import {
  CREATE_SIGNATURES_ERROR,
  CREATE_SIGNATURES_LOADING,
  CREATE_SIGNATURES_SUCCESS,
  FETCH_LEGAL_DOCUMENT_BY_ID_ERROR,
  FETCH_LEGAL_DOCUMENT_BY_ID_LOADING,
  FETCH_LEGAL_DOCUMENT_BY_ID_SUCCESS
} from '../actions/types';

const initialState = {
  loading: false,
  error: false,
  success: false,
  documents: [],
  totalDocuments: 0,
  signatures: {
    loading: false,
    error: false,
    success: false
  }
};

export const legalDocuments = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEGAL_DOCUMENT_BY_ID_LOADING:
      return { ...state, loading: true };
    case FETCH_LEGAL_DOCUMENT_BY_ID_SUCCESS:
      return {
        ...state,
        totalDocuments: state.totalDocuments + 1,
        documents: [...state.documents, action.payload],
        success: true,
        error: false,
        loading: false
      };
    case FETCH_LEGAL_DOCUMENT_BY_ID_ERROR:
      return { ...state, error: true, success: false, loading: false };
    case CREATE_SIGNATURES_LOADING:
      return { ...state, signatures: { ...state.signatures, loading: true } };
    case CREATE_SIGNATURES_SUCCESS:
      return { ...state, signatures: { ...state.signatures, success: true, loading: false } };
    case CREATE_SIGNATURES_ERROR:
      return { ...state, signatures: { ...state.signatures, success: true, loading: false } };
    default:
      return state;
  }
};
