import cloneDeep from 'lodash/cloneDeep';
import {
  FETCH_EQUIPMENTS_DATA_LOADING,
  FETCH_EQUIPMENTS_DATA_SUCCESS,
  FETCH_EQUIPMENTS_DATA_ERROR,
  ADD_EQUIPMENT_LOADING,
  ADD_EQUIPMENT_SUCCESS,
  ADD_EQUIPMENT_ERROR,
  EDIT_EQUIPMENT_LOADING,
  EDIT_EQUIPMENT_SUCCESS,
  EDIT_EQUIPMENT_ERROR,
  DELETE_EQUIPMENT_LOADING,
  DELETE_EQUIPMENT_SUCCESS,
  DELETE_EQUIPMENT_ERROR,
  RESET_EQUIPMENT_STORE,
  FETCH_EXECUTERS_DATA_LOADING,
  FETCH_EXECUTERS_DATA_SUCCESS,
  FETCH_EXECUTERS_DATA_ERROR,
  ADD_EXECUTERS_LOADING,
  ADD_EXECUTERS_SUCCESS,
  ADD_EXECUTERS_ERROR,
  EDIT_EXECUTERS_LOADING,
  EDIT_EXECUTERS_SUCCESS,
  EDIT_EXECUTERS_ERROR,
  DELETE_EXECUTERS_LOADING,
  DELETE_EXECUTERS_SUCCESS,
  DELETE_EXECUTERS_ERROR,
  RESET_EXECUTER_STORE,
  FETCH_WORKERS_DATA_SUCCESS,
  //products
  FETCH_PRODUCTS_DATA_LOADING,
  FETCH_PRODUCTS_DATA_SUCCESS,
  FETCH_PRODUCTS_DATA_ERROR,
  ADD_PRODUCTS_LOADING,
  ADD_PRODUCTS_SUCCESS,
  ADD_PRODUCTS_ERROR,
  EDIT_PRODUCTS_LOADING,
  EDIT_PRODUCTS_SUCCESS,
  EDIT_PRODUCTS_ERROR,
  DELETE_PRODUCTS_LOADING,
  DELETE_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_ERROR,
  RESET_PRODUCT_STORE,
  VISIBILITY_PRODUCTS_LOADING,
  VISIBILITY_PRODUCTS_SUCCESS,
  VISIBILITY_PRODUCTS_ERROR,
  RESET_EXECUTER_DATA,
  RESET_EQUIPMENTS_DATA,
  RESET_WORKERS_DATA,
  //Fertilizers
  FETCH_FERTILIZERS_DATA_LOADING,
  FETCH_FERTILIZERS_DATA_SUCCESS,
  FETCH_FERTILIZERS_DATA_ERROR,
  VISIBILITY_FERTILIZERS_LOADING,
  VISIBILITY_FERTILIZERS_SUCCESS,
  VISIBILITY_FERTILIZERS_ERROR,
  DELETE_FERTILIZERS_LOADING,
  DELETE_FERTILIZERS_SUCCESS,
  DELETE_FERTILIZERS_ERROR,
  RESET_FERTILIZERS_TABLE_DATA,
  ADD_FERTILIZERS_LOADING,
  ADD_FERTILIZERS_SUCCESS,
  ADD_FERTILIZERS_ERROR,
  EDIT_FERTILIZERS_LOADING,
  EDIT_FERTILIZERS_SUCCESS,
  EDIT_FERTILIZERS_ERROR
} from '../actions/types';
import { PRODUCT_TYPES } from './productTypes';

const initialState = {
  equipmentsLoading: false,
  equipmentsError: null,
  equipmentsData: [],
  equipmentsDataSuccess: false,
  equipmentsAddLoading: false,
  equipmentsAddError: null,
  equipmentsAddSuccess: false,
  equipmentsEditLoading: false,
  equipmentsEditError: null,
  equipmentsEditSuccess: false,
  equipmentsDeleteLoading: false,
  equipmentsDeleteError: null,
  equipmentsDeleteSuccess: false,

  executersLoading: false,
  executersError: null,
  executersData: [],
  workersData: [],
  executersDataSuccess: false,
  executersAddLoading: false,
  executersAddError: null,
  executersAddSuccess: false,
  executersEditLoading: false,
  executersEditError: null,
  executersEditSuccess: false,
  executersDeleteLoading: false,
  executersDeleteError: null,
  executersDeleteSuccess: false,
  executersTotalCount: 0,

  productsLoading: false,
  productsError: null,
  productsData: [],
  productsDataSuccess: false,
  productsAddLoading: false,
  productsAddError: null,
  productsAddSuccess: false,
  productsEditLoading: false,
  productsEditError: null,
  productsEditSuccess: false,
  productsDeleteLoading: false,
  productsDeleteError: null,
  productsDeleteSuccess: false,
  productsTotalCount: 0,

  productsVisibilityLoading: false,
  productsVisibilityError: null,
  productsVisibilitySuccess: false,

  productTypes: PRODUCT_TYPES,

  fertilizersLoading: false,
  fertilizersData: [],
  fertilizersDataSuccess: false,
  fertilizersError: null,
  addfertilizerLoading: false,
  addFertilizerSuccess: null,
  addfertilizerError: false,
  editFertilizerLoading: false,
  editFertilizerSuccess: false,
  editFertilizerError: false,
  fertilizersDeleteLoading: false,
  fertilizersDeleteSuccess: false,
  fertilizersDeleteError: null,
  fertilizersVisibilityError: null,
  fertilizersVisibilityLoading: false,
  fertilizersVisibilitySuccess: false
};

export const resources = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EQUIPMENTS_DATA_LOADING:
      return {
        ...state,
        equipmentsLoading: true,
        equipmentsDataSuccess: false
      };
    case FETCH_EQUIPMENTS_DATA_SUCCESS:
      return {
        ...state,
        equipmentsData: [...state.equipmentsData, ...action.payload],
        equipmentsLoading: false,
        equipmentsDataSuccess: true
      };
    case FETCH_EQUIPMENTS_DATA_ERROR:
      return {
        ...state,
        equipmentsError: action.error,
        equipmentsLoading: false,
        equipmentsDataSuccess: false
      };

    case RESET_EQUIPMENTS_DATA:
      return {
        ...state,
        equipmentsData: []
      };
    //equipments
    case ADD_EQUIPMENT_LOADING:
      return {
        ...state,
        equipmentsAddLoading: true,
        equipmentsAddSuccess: false,
        equipmentsAddError: null,
        equipmentsEditSuccess: false,
        equipmentsEditError: null,
        equipmentsDeleteSuccess: false,
        equipmentsDeleteError: null
      };
    case ADD_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipmentsData: [action.payload, ...state.equipmentsData],

        equipmentsAddLoading: false,
        equipmentsAddSuccess: true
      };
    case ADD_EQUIPMENT_ERROR:
      return {
        ...state,
        equipmentsAddError: action.error,
        equipmentsAddLoading: false,
        equipmentsAddSuccess: false
      };

    case EDIT_EQUIPMENT_LOADING:
      return {
        ...state,
        equipmentsEditLoading: true,
        equipmentsEditSuccess: false,
        equipmentsAddSuccess: false,
        equipmentsAddError: null,
        equipmentsEditError: null,
        equipmentsDeleteSuccess: false,
        equipmentsDeleteError: null
      };
    case EDIT_EQUIPMENT_SUCCESS:
      const indexEquipments = state.equipmentsData.findIndex(
        (item) => item.id === action.payload.id
      );
      const updateEquipments = cloneDeep(state.equipmentsData);
      updateEquipments.splice(indexEquipments, 1, action.payload);

      return {
        ...state,
        equipmentsData: updateEquipments,
        equipmentsEditLoading: false,
        equipmentsEditSuccess: true
      };
    case EDIT_EQUIPMENT_ERROR:
      return {
        ...state,
        equipmentsEditError: action.error,
        equipmentsEditLoading: false,
        equipmentsEditSuccess: false
      };
    case DELETE_EQUIPMENT_LOADING:
      return {
        ...state,
        equipmentsDeleteLoading: true,
        equipmentsDeleteSuccess: false,
        equipmentsAddSuccess: false,
        equipmentsAddError: null,
        equipmentsEditSuccess: false,
        equipmentsEditError: null,
        equipmentsDeleteError: null
      };
    case DELETE_EQUIPMENT_SUCCESS:
      const filteredEquipments = state.equipmentsData.filter((item) => item.id !== action.payload);
      return {
        ...state,
        equipmentsData: filteredEquipments,
        equipmentsDeleteLoading: false,
        equipmentsDeleteSuccess: true
      };
    case DELETE_EQUIPMENT_ERROR:
      return {
        ...state,
        equipmentsDeleteError: action.error,
        equipmentsDeleteLoading: false,
        equipmentsDeleteSuccess: false
      };
    case RESET_EQUIPMENT_STORE:
      return {
        ...state,
        equipmentsLoading: false,
        equipmentsError: null,
        equipmentsDataSuccess: false,
        equipmentsAddLoading: false,
        equipmentsAddError: null,
        equipmentsAddSuccess: false,
        equipmentsEditLoading: false,
        equipmentsEditError: null,
        equipmentsEditSuccess: false,
        equipmentsDeleteLoading: false,
        equipmentsDeleteError: null,
        equipmentsDeleteSuccess: false
      };

    //executers
    case FETCH_EXECUTERS_DATA_LOADING:
      return {
        ...state,
        executersLoading: true,
        executersDataSuccess: false,
        executersEditSuccess: false,
        executersAddSuccess: false,
        executersDeleteSuccess: false,
        executersEditError: false,
        executersAddError: false,
        executersDeleteError: false
      };
    case FETCH_EXECUTERS_DATA_SUCCESS:
      return {
        ...state,
        executersData: [...state.executersData, ...action.payload],
        executersLoading: false,
        executersDataSuccess: true,
        executersTotalCount: action.payload.total_elements
      };
    case FETCH_WORKERS_DATA_SUCCESS:
      return {
        ...state,
        workersData: [...state.workersData, ...action.payload],
        executersLoading: false,
        executersDataSuccess: true,
        executersTotalCount: action.payload.total_elements
      };
    case FETCH_EXECUTERS_DATA_ERROR:
      return {
        ...state,
        executersError: action.error,
        executersLoading: false,
        executersDataSuccess: false
      };

    case ADD_EXECUTERS_LOADING:
      return {
        ...state,
        executersAddLoading: true,
        executersAddSuccess: false,
        executersEditSuccess: false,
        executersDeleteSuccess: false,
        executersEditError: false,
        executersAddError: false,
        executersDeleteError: false
      };
    case ADD_EXECUTERS_SUCCESS:
      return {
        ...state,
        executersData: [...state.executersData, action.payload],
        workersData: [...state.workersData, action.payload],
        executersAddLoading: false,
        executersAddSuccess: true
      };
    case ADD_EXECUTERS_ERROR:
      return {
        ...state,
        executersAddError: action.error,
        executersAddLoading: false,
        executersAddSuccess: false
      };
    case EDIT_EXECUTERS_LOADING:
      return {
        ...state,
        executersEditLoading: true,
        executersEditSuccess: false,
        executersAddSuccess: false,
        executersDeleteSuccess: false,
        executersEditError: false,
        executersAddError: false,
        executersDeleteError: false
      };
    case EDIT_EXECUTERS_SUCCESS:
      const updateExecutors = editExecuters(state.executersData, action.payload);
      const updateWorkers = editExecuters(state.workersData, action.payload);
      return {
        ...state,
        executersData: updateExecutors,
        workersData: updateWorkers,
        executersEditLoading: false,
        executersEditSuccess: true
      };
    case EDIT_EXECUTERS_ERROR:
      return {
        ...state,
        executersEditError: action.error,
        executersEditLoading: false,
        executersEditSuccess: false
      };

    case DELETE_EXECUTERS_LOADING:
      return {
        ...state,
        executersDeleteLoading: true,
        executersDeleteSuccess: false,
        executersEditSuccess: false,
        executersAddSuccess: false,
        executersEditError: false,
        executersAddError: false,
        executersDeleteError: false
      };
    case DELETE_EXECUTERS_SUCCESS:
      const newExecutersData = state.executersData.filter((item) => item.id !== action.payload);
      const newWorkersData = state.workersData.filter((item) => item.id !== action.payload);
      return {
        ...state,
        executersData: newExecutersData,
        workersData: newWorkersData,
        executersDeleteLoading: false,
        executersDeleteSuccess: true
      };
    case DELETE_EXECUTERS_ERROR:
      return {
        ...state,
        executersDeleteError: action.error,
        executersDeleteLoading: false,
        executersDeleteSuccess: false
      };
    case RESET_EXECUTER_STORE:
      return {
        ...state,
        executersLoading: false,
        executersError: null,
        executersDataSuccess: false,
        executersAddLoading: false,
        executersAddError: null,
        executersAddSuccess: false,
        executersEditLoading: false,
        executersEditError: null,
        executersEditSuccess: false,
        executersDeleteLoading: false,
        executersDeleteError: null,
        executersDeleteSuccess: false
      };

    case RESET_EXECUTER_DATA:
      return {
        ...state,
        executersData: []
      };
    case RESET_WORKERS_DATA:
      return {
        ...state,
        workersData: []
      };
    //Products
    case FETCH_PRODUCTS_DATA_LOADING:
      return {
        ...state,
        productsLoading: true,
        productsDataSuccess: false,
        productsError: null,
        productsAddSuccess: false,
        productsEditSuccess: false,
        productsDeleteSuccess: false,
        productsAddError: null,
        productsEditError: null,
        productsDeleteError: null,
        fertilizersDeleteError: null
      };
    case FETCH_PRODUCTS_DATA_SUCCESS:
      let data = [];
      data = action.payload.content.content;
      return {
        ...state,
        productsError: null,
        productsData: data,
        productsLoading: false,
        productsDataSuccess: true,
        productsTotalCount: action.payload.total_elements
      };
    case FETCH_PRODUCTS_DATA_ERROR:
      return {
        ...state,
        productsError: action.error,
        productsLoading: false,
        productsDataSuccess: false
      };
    case ADD_PRODUCTS_LOADING:
      return {
        ...state,
        productsAddLoading: true,
        productsAddSuccess: false,
        productsEditSuccess: false,
        productsDeleteSuccess: false,
        productsAddError: null,
        productsEditError: null,
        productsDeleteError: null,
        productsError: null
      };
    case ADD_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsData: [...state.productsData, action.payload],
        productsAddLoading: false,
        productsAddSuccess: true
      };
    case ADD_PRODUCTS_ERROR:
      return {
        ...state,
        productsAddError: action.error,
        productsAddLoading: false,
        productsAddSuccess: false
      };

    case EDIT_PRODUCTS_LOADING:
      return {
        ...state,
        productsEditLoading: true,
        productsEditSuccess: false,
        productsAddSuccess: false,
        productsDeleteSuccess: false,
        productsAddError: null,
        productsEditError: null,
        productsDeleteError: null,
        productsError: null
      };
    case EDIT_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsEditLoading: false,
        productsEditSuccess: true
      };
    case EDIT_PRODUCTS_ERROR:
      return {
        ...state,
        productsEditError: action.error,
        productsEditLoading: false,
        productsEditSuccess: false
      };

    case DELETE_PRODUCTS_LOADING:
      return {
        ...state,
        productsDeleteLoading: true,
        productsDeleteSuccess: false,
        productsEditSuccess: false,
        productsAddSuccess: false,
        productsAddError: null,
        productsEditError: null,
        productsDeleteError: null,
        productsError: null
      };
    case DELETE_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsDeleteLoading: false,
        productsDeleteSuccess: true
      };
    case DELETE_PRODUCTS_ERROR:
      return {
        ...state,
        productsDeleteError: action.error,
        productsDeleteLoading: false,
        productsDeleteSuccess: false
      };
    case VISIBILITY_PRODUCTS_LOADING:
      return {
        ...state,
        productsVisibilityLoading: true,
        productsVisibilitySuccess: false,
        productsVisibilityError: null
      };
    case VISIBILITY_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsVisibilityLoading: false,
        productsVisibilitySuccess: true
      };
    case VISIBILITY_PRODUCTS_ERROR:
      return {
        ...state,
        productsVisibilityError: action.error,
        productsVisibilityLoading: false,
        productsVisibilitySuccess: false
      };
    case RESET_PRODUCT_STORE:
      return {
        ...state,
        productsLoading: false,
        productsError: null,
        productsDataSuccess: false,
        productsAddLoading: false,
        productsAddError: null,
        productsAddSuccess: false,
        productsEditLoading: false,
        productsEditError: null,
        productsEditSuccess: false,
        productsDeleteLoading: false,
        productsDeleteError: null,
        productsDeleteSuccess: false
      };

    //Fertilizers
    case ADD_FERTILIZERS_LOADING:
      return {
        ...state,
        addfertilizerLoading: true,
        addFertilizerSuccess: null,
        addfertilizerError: false
      };
    case ADD_FERTILIZERS_SUCCESS:
      return {
        ...state,
        addfertilizerLoading: false,
        addFertilizerSuccess: action.payload,
        addfertilizerError: false
      };
    case ADD_FERTILIZERS_ERROR:
      return {
        ...state,
        addfertilizerLoading: false,
        addFertilizerSuccess: null,
        addfertilizerError: true
      };
    case EDIT_FERTILIZERS_LOADING:
      return {
        ...state,
        editFertilizerLoading: true,
        editFertilizerSuccess: false,
        editFertilizerError: false
      };
    case EDIT_FERTILIZERS_SUCCESS:
      return {
        ...state,
        editFertilizerLoading: false,
        editFertilizerSuccess: true
      };
    case EDIT_FERTILIZERS_ERROR:
      return {
        ...state,
        editFertilizerLoading: false,
        editFertilizerError: true
      };
    case VISIBILITY_FERTILIZERS_LOADING:
      return {
        ...state,
        fertilizersVisibilityLoading: true,
        fertilizersVisibilitySuccess: false,
        fertilizersVisibilityError: null
      };
    case VISIBILITY_FERTILIZERS_SUCCESS: {
      const updatedData = state.fertilizersData.map((fertilizer) => {
        const updatedFertilizer = action.payload.find((o) => o.fertilizer_id === fertilizer.id);
        return updatedFertilizer
          ? { ...fertilizer, hidden: updatedFertilizer.status === 'HIDDEN' }
          : fertilizer;
      });
      return {
        ...state,
        fertilizersData: updatedData,
        fertilizersVisibilityLoading: false,
        fertilizersVisibilitySuccess: true,
        fertilizersDeleteSuccess: false
      };
    }
    case VISIBILITY_FERTILIZERS_ERROR:
      return {
        ...state,
        fertilizersVisibilityError: action.error,
        fertilizersVisibilityLoading: false,
        fertilizersVisibilitySuccess: false
      };
    case DELETE_FERTILIZERS_LOADING:
      return {
        ...state,
        fertilizersDeleteLoading: true,
        fertilizersDeleteSuccess: false,
        fertilizersDeleteError: null
      };
    case DELETE_FERTILIZERS_SUCCESS:
      return {
        ...state,
        fertilizersData: state.fertilizersData.filter((fertilizer) => !action.payload.includes(fertilizer.id)),
        fertilizersDeleteLoading: false,
        fertilizersDeleteSuccess: true
      };
    case DELETE_FERTILIZERS_ERROR:
      return {
        ...state,
        fertilizersDeleteError: action.error,
        fertilizersDeleteLoading: false,
        fertilizersDeleteSuccess: false
      };
    case FETCH_FERTILIZERS_DATA_LOADING:
      return {
        ...state,
        fertilizersLoading: true,
        fertilizersDataSuccess: false,
        addFertilizerSuccess: null,
      };
    case FETCH_FERTILIZERS_DATA_SUCCESS:
      return {
        ...state,
        fertilizersData: action.payload,
        fertilizersLoading: false,
        fertilizersDataSuccess: true,
        fertilizersNextPage: action.fertilizersNextPage
      };
    case FETCH_FERTILIZERS_DATA_ERROR:
      return {
        ...state,
        fertilizersError: action.error,
        fertilizersLoading: false,
        fertilizersDataSuccess: false
      };

    case RESET_FERTILIZERS_TABLE_DATA:
      return {
        ...state,
        fertilizersData: []
      };

    default:
      return state;
  }
};

function editExecuters(data, payload) {
  const indexExecuters = data.findIndex((item) => item.id === payload.id);
  const updateExecutors = cloneDeep(data);
  updateExecutors.splice(indexExecuters, 1, payload);
  return updateExecutors;
}
