import { showNotification } from 'components/common/showNotification';
import { DownloadShapeDrawer } from 'components/cropcycle-rebranding/downloadShape';
import { AllFieldsDeleteConfirmModal } from 'pages/fields/list/allFieldsDeleteConfirmModal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { createBulkFieldsByPropId } from 'redux/actions/fields';
import { AppConstants } from 'utils/app.constants';
import { FieldModel } from '../../archiving.model';
import { getGlobalAnalyticsProps, useGlobalAnalytics } from 'utils/analytics/contextual-tracking.hooks';
import { fieldsToAreaArray } from 'utils/commonMethods';

/**
 * TODO: We should refactor these two modals to use the new folder structure so it's easier to use them across the whole app
 * For now we're using it to avoid code duplication
 */
export function DownloadAndDeleteModals({
  deleteOpen,
  setDeleteOpen,
  downloadOpen,
  setDownloadOpen,
  selectedFields,
  setSelectedFields,
  fetchArchivedFields
}: {
  deleteOpen: boolean;
  setDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  downloadOpen: boolean;
  setDownloadOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFields: { id: string }[];
  setSelectedFields: React.Dispatch<React.SetStateAction<string[]>>;
  fetchArchivedFields: () => Promise<FieldModel[]>;
}) {
  const track = useGlobalAnalytics();
  const { t } = useTranslation();
  const { id: orgId, farmId } = useParams();

  const [deletingFields, setDeletingFields] = useState<boolean>(false);
  const navigate = useNavigate();

  const currentProperty = useSelector(
    (state: any) => state?.propertiesList?.data?.entities?.properties?.[farmId as string] || {}
  );

  const handleDeleteFields = async () => {
    setDeletingFields(true);
    const reqobj: any = {
      parentId: currentProperty?.root_region_id,
      newFields: selectedFields,
      orgId,
      farmId,
      operation: 'delete',
      fieldValueFromState: [],
      allFieldsDeleteClicked: true,
      navigate
    };
    try {
      await createBulkFieldsByPropId(reqobj);
      setDeleteOpen(false);
      fetchArchivedFields();
      setDeletingFields(false);
      setSelectedFields([]);
      track('Fields - Deleted Field', {
        areaInHectars: fieldsToAreaArray(selectedFields)
      });
      showNotification(
        'success',
        t('global.notification.successfully_deleted'),
        t('create_edit_field.notification.content.field_delete_success_text')
      );
    } catch (e) {
      showNotification(
        'failed',
        t('create_edit_field.notification.title.field_delete_failed'),
        t('create_edit_field.error_message')
      );
      setDeletingFields(false);
      setDeleteOpen(false);
      setSelectedFields([]);
    }
  };

  return (
    <>
      <DownloadShapeDrawer
        showDownloadShapeDrawer={downloadOpen}
        setShowDownloadShapeDrawer={setDownloadOpen}
        setSelectedFieldIds={setSelectedFields}
        totalAreaSelected={selectedFields.map((id) => ({ id }))}
        property={currentProperty}
        dateFormat={AppConstants.DATE_FORMATS_SETTINGS[0]}
        eventPayload={getGlobalAnalyticsProps()}
      />
      <AllFieldsDeleteConfirmModal
        showAllFieldsDeleteConfirm={deleteOpen}
        setShowAllFieldsDeleteConfirm={setDeleteOpen}
        handleDeleteFields={handleDeleteFields}
        deletingFields={deletingFields}
        setDeletingFields={setDeletingFields}
        totalAreaSelected={selectedFields}
        setSelectedFieldIds={setSelectedFields}
      />
    </>
  );
}
