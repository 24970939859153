import isEmpty from 'lodash/isEmpty';
import { matchPath } from 'react-router';

const IS_CREATE_PROPERTY_SESSION_ACTIVE = 'IS_CREATE_PROPERTY_SESSION_ACTIVE';

export const isShowStepper = (location, params) => {
  const isHavingProperty = !!matchPath(
    {
      path: '/app/landing-organization/:id/property/:farmId'
    },
    location.pathname
  );

  const isHavingEditProperty = !!matchPath(
    {
      path: '/app/landing-organization/:id/edit-farm/:farmId'
    },
    location.pathname
  );

  let propertyId;
  let setupPropertyId;

  if (isHavingProperty || isHavingEditProperty) {
    propertyId = params && params.farmId;
    setupPropertyId = sessionStorage.getItem('Setup_Property');
    return propertyId === setupPropertyId;
  } else {
    return false;
  }
};

export const isCreatePropertySessionActive = () => {
  if (
    sessionStorage.getItem(IS_CREATE_PROPERTY_SESSION_ACTIVE) === 'false' ||
    isEmpty(sessionStorage.getItem(IS_CREATE_PROPERTY_SESSION_ACTIVE))
  ) {
    return false;
  } else {
    return true;
  }
};

export const setIsCreatePropertySessionActive = (value) => {
  sessionStorage.setItem(IS_CREATE_PROPERTY_SESSION_ACTIVE, value);
};
