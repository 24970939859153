import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { showNotification } from 'components/common/showNotification';
import { useTranslation } from 'react-i18next';
import { history, URL_CONSTANTS } from 'utils/history';
import {
  initialInternalState,
  ArchivingModalsEnum,
  ArchivingInternalProvider
} from './archiving-internal.provider';
import { ArchiveError } from './archive/archive-error/archive-error.component';
import { ConfirmArchive } from './archive/confirm-archive/confirm-archive.component';
import { ConfirmUnarchive } from './unarchive/confirm-unarchive/confirm-unarchive.component';
import { UnarchiveError } from './unarchive/unarchive-error/unarchive-error.component';
import * as service from './archiving.service';
import { FieldModel } from './archiving.model';
import { getFieldWithParentName } from './archiving.util';
import { clearFieldsCache } from 'redux/actions/fields';

interface ArchivingContextValue {
  fields: FieldModel[];
  loading: boolean;
  loadingRegionNames: boolean;
  openConfirmArchive: (fields: string[]) => void;
  openConfirmUnarchive: (fieldId: string) => void;
  fetchArchivedFields: (force?: boolean) => Promise<FieldModel[]>;
}
const ArchivingContext = React.createContext<ArchivingContextValue>({} as ArchivingContextValue);

export function useArchiving() {
  return useContext(ArchivingContext);
}

const getOrgAndFarmId = () => {
  const pathname = window.location.pathname;
  const farmId = pathname.split('/')[5];
  const orgId = pathname.split('/')[3];

  return [orgId, farmId];
};

export function ArchivingProvider(props: { children?: ReactNode }) {
  const { t } = useTranslation();

  const [state, setState] = useState(initialInternalState);
  const [fields, setFields] = useState<FieldModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingRegionNames, setLoadingRegionNames] = useState(true);
  const [currentFarmId, setCurrentFarmId] = useState<string | null>(null);

  const resetState = () => {
    setState(initialInternalState);
  };

  const triggerModal = (modal: ArchivingModalsEnum) => {
    setState((state) => ({ ...state, modals: { ...state.modals, [modal]: !state.modals[modal] } }));
  };

  const triggerLoading = () => {
    setState((state) => ({ ...state, loading: !state.loading }));
  };

  const goToSeasons = () => {
    const [orgId, farmId] = getOrgAndFarmId();
    resetState();
    history.push(URL_CONSTANTS.LANDING_PROPERTY_TABLE_VIEW({ orgId, farmId }));
  };

  const archiveFields = () => {
    const [orgId, farmId] = getOrgAndFarmId();

    triggerLoading();

    const fieldsInActiveSeasonIds = state.fieldsInActiveSeason.map((field) => field.id);
    const fieldsToArchive = state.selectedFieldsIds.filter(
      (id) => !fieldsInActiveSeasonIds.includes(id)
    );

    service
      .archiveFields(fieldsToArchive)
      .then((result) => {
        if (result.success) {
          // TODO: Improve this call when we develop the Cache Service
          clearFieldsCache();

          showNotification(
            'success',
            t('archiving.notifications.field_archived', { count: fieldsToArchive.length }),
            t('archiving.notifications.field_archived_description', {
              count: fieldsToArchive.length
            })
          );
          resetState();
          history.push(URL_CONSTANTS.ARCHIVED_FIELDS({ orgId, farmId }));
        } else if (result.activeSeasonError) {
          setState((state) => ({
            ...state,
            fieldsInActiveSeason: result.activeSeasonError.errors.map((err) => ({
              id: err.field_id,
              name: err.field_name,
              season: err.season.season_name
            }))
          }));
          triggerModal(ArchivingModalsEnum.ARCHIVE_ERROR);
        }
      })
      .finally(() => {
        triggerLoading();
      });
  };

  const unarchiveField = () => {
    if (state.selectedFieldsIds.length === 1) {
      triggerLoading();

      service
        .unarchiveFields(state.selectedFieldsIds[0])
        .then((result) => {
          if (result.success) {
            // TODO: Improve this call when we develop the Cache Service
            clearFieldsCache();

            showNotification(
              'success',
              t('archiving.notifications.field_unarchived'),
              t('archiving.notifications.field_unarchived_description')
            );
            resetState();
            fetchArchivedFields(true);
          } else if (result.overlapError) {
            const currentField = result.overlapError.errors[0].overlap.field;
            const overlappingFields = result.overlapError.errors.map(
              ({ overlap: { field_overlapped } }) => field_overlapped
            );

            setState((state) => ({
              ...state,
              overlappingFields: [currentField, ...overlappingFields].map((field) => ({
                id: field.field_id,
                name: field.field_name,
                area: field.field_declared_area || field.field_calculated_area
              }))
            }));
            triggerModal(ArchivingModalsEnum.CONFIRM_UNARCHIVE);
            triggerModal(ArchivingModalsEnum.UNARCHIVE_ERROR);
          }
        })
        .finally(() => {
          triggerLoading();
        });
    }
  };

  const fetchArchivedFields = useCallback((force: boolean = false) => {
    const [, farmId] = getOrgAndFarmId();

    if (farmId && (currentFarmId !== farmId || force)) {
      setCurrentFarmId(farmId);
      setLoading(true);
      setLoadingRegionNames(true);

      const getFieldsPromise = service
        .getArchivedFields(farmId)
        .then((fields) => {
          setFields(fields);
          return fields;
        })
        .finally(() => {
          setLoading(false);
        });

      // Wait fields to be loaded before set regionNames
      return Promise.all([getFieldsPromise, service.getPropertyRegionNames(farmId)])
        .then(([fields, regionNames]) => {
          const fieldsWithNames = fields.map((field) => getFieldWithParentName(field, regionNames));
          setFields(fieldsWithNames);
          return fieldsWithNames;
        })
        .finally(() => setLoadingRegionNames(false));
    } else {
      return Promise.resolve([]);
    }
  }, [currentFarmId]);

  const value = useMemo(
    () =>
      ({
        fields,
        loading,
        loadingRegionNames,
        openConfirmArchive: (selectedFieldsIds) => {
          triggerModal(ArchivingModalsEnum.CONFIRM_ARCHIVE);
          setState((state) => ({
            ...state,
            loading: false,
            selectedFieldsIds
          }));
        },
        openConfirmUnarchive: (fieldId) => {
          triggerModal(ArchivingModalsEnum.CONFIRM_UNARCHIVE);
          setState((state) => ({
            ...state,
            loading: false,
            selectedFieldsIds: [fieldId]
          }));
        },
        fetchArchivedFields
      } as ArchivingContextValue),
    [fields, loading, loadingRegionNames, fetchArchivedFields]
  );

  return (
    <ArchivingContext.Provider value={value}>
      <ArchivingInternalProvider
        value={{
          ...state,
          triggerModal,
          goToSeasons,
          archiveFields,
          unarchiveField,
          resetState
        }}>
        <ConfirmArchive />
        <ArchiveError />
        <ConfirmUnarchive />
        <UnarchiveError />
      </ArchivingInternalProvider>
      {props.children}
    </ArchivingContext.Provider>
  );
}
