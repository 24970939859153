import { setItem, getItem } from 'localforage';

/**
 * By default localForage prefers using these databases (in this particular order):
 * IndexedDB
 * WebSQL
 * localStorage
 */
export const localForge = {
  setItem,
  getItem
};
