/* eslint-disable no-useless-escape, react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars */
import { isEmpty } from "lodash";
import { useCallback } from "react";

export const useRbacMethods = () => {

  const checkIsValidRbacPayloadData = useCallback((rbacPayload: any) => {
    const invalidResource = rbacPayload.find((operation: any) => isEmpty(operation.resource.match(ALL_ALLOWED_VERIFY_RESOURCES)));
    return !invalidResource;
  }, []);

  const WILD_CARD_REGEX = '^\\*$';
  const SYSTEM_CRN_REGEX = '^crn:system$';
  const APP_CRN_REGEX_DASHED_UUID = `^crn:app:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$`;
  const WORKSPACE_WILD_REGEX = `^crn:workspace:\\*$`;
  /**@deprecated - it's being replaced to channel_level*/
  const WORKSPACE_CRN_REGEX_DASHED_UUID = `^crn:workspace:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$`;
  /**@deprecated - it's being replaced to channel_level*/
  const WORKSPACE_WITH_ORG_WILD_REGEX_DASHED_UUID = `^crn:workspace:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/org\/\\*$`;
  /**@deprecated - it's being replaced to channel_level*/
  const WORKSPACE_WITH_ORG_CRN_REGEX_DASHED_UUID = `^crn:workspace:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/org\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$`;
  /**@deprecated - it's being replaced to channel_level*/
  const WORKSPACE_WITH_PROPERTY_WILD_REGEX_DASHED_UUID = `^crn:workspace:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/org\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/property\/\\*$`;
  /**@deprecated - it's being replaced to channel_level*/
  const WORKSPACE_WITH_PROPERTY_CRN_REGEX_DASHED_UUID = `^crn:workspace:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/org\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/property\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$`;
  const WORKSPACE_RESOURCE_ATTACHMENT = 'crn:workspace:{workspace_id}';
  /**@deprecated - it's being replaced to channel_level*/
  const ORG_RESOURCE_ATTACHMENT = `${WORKSPACE_RESOURCE_ATTACHMENT}/org/{org_id}`;
  /**@deprecated - it's being replaced to channel_level*/
  const PROPERTY_RESOURCE_ATTACHMENT = `${ORG_RESOURCE_ATTACHMENT}/property/{property_id}`;
  // end - deprecated - workspace resources
  const CHANNEL_LEVEL_CRN_REGEX_DASHED_UUID = `^crn:channel_level:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$`;
  const CHANNEL_LEVEL_WILD_CARD_CRN_REGEX_DASHED_UUID = `^crn:channel_level:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/channel_level\/\*$`;
  const ORG_CRN_REGEX_DASHED_UUID = `^crn:org:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$`;
  const ORG_WITH_PROPERTY_WILD_REGEX_DASHED_UUID = `^crn:org:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/property\/\*$`;
  const ORG_WITH_PROPERTY_CRN_REGEX_DASHED_UUID = `^crn:org:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/property\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$`;
  const APP_RESOURCE_ATTACHMENT = 'crn:app:{app_id}';
  const SYSTEM_RESOURCE_ATTACHMENT = 'crn:system';
  const NONE_RESOURCE_ATTACHMENT = 'none';
  const CHANNEL_LEVEL_RESOURCE_ATTACHMENT = 'crn:channel_level:{channel_level_id}';


  const ALL_ALLOWED_VERIFY_RESOURCES = new RegExp(
    [
      WILD_CARD_REGEX,
      SYSTEM_CRN_REGEX,
      APP_CRN_REGEX_DASHED_UUID,
      CHANNEL_LEVEL_CRN_REGEX_DASHED_UUID,
      ORG_CRN_REGEX_DASHED_UUID,
      ORG_WITH_PROPERTY_WILD_REGEX_DASHED_UUID,
      ORG_WITH_PROPERTY_CRN_REGEX_DASHED_UUID,
      SYSTEM_CRN_REGEX,
      APP_CRN_REGEX_DASHED_UUID,
      WORKSPACE_WILD_REGEX,
      CHANNEL_LEVEL_CRN_REGEX_DASHED_UUID,
      ORG_CRN_REGEX_DASHED_UUID,
      ORG_WITH_PROPERTY_WILD_REGEX_DASHED_UUID,
      ORG_WITH_PROPERTY_CRN_REGEX_DASHED_UUID,
      WORKSPACE_CRN_REGEX_DASHED_UUID,
      WORKSPACE_WITH_ORG_CRN_REGEX_DASHED_UUID,
      WORKSPACE_WITH_ORG_WILD_REGEX_DASHED_UUID,
      WORKSPACE_WITH_PROPERTY_CRN_REGEX_DASHED_UUID,
      WORKSPACE_WITH_PROPERTY_WILD_REGEX_DASHED_UUID,
    ].join('|'),
    'i',
  );

  return { checkIsValidRbacPayloadData, ALL_ALLOWED_VERIFY_RESOURCES };
}