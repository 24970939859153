import { LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { PropsWithChildren } from 'react';
import * as Styled from './styled-modal.style';

interface StyledModalBtnProps {
  onClick: () => void;
  text: string;
}

interface StyledModalProps {
  open: boolean;
  title: string;
  width?: number;
  showClose?: boolean;
  onClose?: () => void;
  cancel?: StyledModalBtnProps;
  ok?: StyledModalBtnProps;
  loading?: boolean;
  error?: boolean;
}

export const StyledModal = ({
  open,
  title,
  width = 420,
  showClose,
  cancel,
  ok,
  loading,
  error,
  onClose,
  children
}: PropsWithChildren<StyledModalProps>) => {
  return (
    <Modal open={open} centered closable={false} maskClosable={false} footer={null} width={width}>
      <Styled.Header>
        <Styled.WarningIcon $error={error} />
        <Styled.Title style={{ flex: 1 }}>{title}</Styled.Title>
        {showClose && <Styled.CloseIcon onClick={onClose} />}
      </Styled.Header>
      {children}
      {(cancel || ok) && (
        <Styled.Footer>
          {cancel && <Styled.Btn onClick={cancel.onClick}>{cancel.text}</Styled.Btn>}
          {ok && (
            <Styled.OKBtn onClick={ok.onClick} disabled={loading}>
              {ok.text}
              {loading && <LoadingOutlined />}
            </Styled.OKBtn>
          )}
        </Styled.Footer>
      )}
    </Modal>
  );
};
