import { getOrgIdFromUrl, getWSOrgPropertyResource, getWSOrgResource } from 'redux/actions/user';
import { EDIT_FARM_ROUTE } from 'utils/history';

export const RBAC_ACTIONS = {
  products: {
    write: 'products:write',
    new: 'products:new',
    delete: 'products:delete',
    read: 'products:read'
  },
  property: {
    new: 'property:new',
    read: 'property:read',
    write: 'property:write',
    delete: 'property:delete'
  },
  fields: {
    new: 'fields:new',
    delete: 'fields:delete',
    write: 'fields:write',
    read: 'fields:read'
  },
  organization: {
    new: 'organization:new',
    read: 'organization:read',
    write: 'organization:write',
    delete: 'organization:delete'
  },
  equipments: {
    new: 'equipments:new',
    read: 'equipments:read',
    write: 'equipments:write',
    delete: 'equipments:delete'
  },
  buildings: {
    new: 'building:new',
    read: 'building:read',
    write: 'building:write',
    delete: 'building:delete'
  },
  assignees: {
    new: 'assignees:new',
    read: 'assignees:read',
    write: 'assignees:write',
    delete: 'assignees:delete'
  },
  cropzone: {
    new: 'crop_zone:new',
    read: 'crop_zone:read',
    write: 'crop_zone:write',
    delete: 'crop_zone:delete'
  },
  cropcycle: {
    new: 'crop_cycle:new',
    read: 'crop_cycle:read',
    write: 'crop_cycle:write',
    delete: 'crop_cycle:delete'
  }
} as const;

export const GET_ORG_RBAC_CRUD_PAYLOAD = (currentWorkspace: string, orgId: string) => {
  return [
    {
      action: RBAC_ACTIONS.organization.read,
      resource: `crn:workspace:${currentWorkspace}/org/${orgId}`
    },
    {
      action: RBAC_ACTIONS.organization.write,
      resource: `crn:workspace:${currentWorkspace}/org/${orgId}`
    },
    {
      action: RBAC_ACTIONS.organization.delete,
      resource: `crn:workspace:${currentWorkspace}/org/${orgId}`
    }
  ];
};

export const GET_RESOURCES_RBAC_VIEW_ACTIONS = (params = {}) => {
  const wsOrgResource = getWSOrgResource(params);
  return [
    { action: RBAC_ACTIONS.products.read, resource: wsOrgResource },
    { action: RBAC_ACTIONS.equipments.read, resource: wsOrgResource },
    { action: RBAC_ACTIONS.assignees.read, resource: wsOrgResource },
    { action: RBAC_ACTIONS.buildings.read, resource: wsOrgResource }
  ];
};

// CUD stands for Create Update Delete
export const GET_RESOURCES_PRODUCT_CUD_ACTIONS = () => {
  const wsOrgResource = getWSOrgResource();
  return [
    { action: RBAC_ACTIONS.products.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.products.new, resource: wsOrgResource },
    { action: RBAC_ACTIONS.products.delete, resource: wsOrgResource }
  ];
};

export const GET_RESOURCES_BUILDINGS_CUD_ACTIONS = () => {
  const wsOrgResource = getWSOrgResource();
  return [
    { action: RBAC_ACTIONS.buildings.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.buildings.new, resource: wsOrgResource },
    { action: RBAC_ACTIONS.buildings.delete, resource: wsOrgResource }
  ];
};

export const GET_RESOURCES_EQUIPMENTS_CUD_ACTIONS = () => {
  const wsOrgResource = getWSOrgResource();
  return [
    { action: RBAC_ACTIONS.equipments.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.equipments.new, resource: wsOrgResource },
    { action: RBAC_ACTIONS.equipments.delete, resource: wsOrgResource }
  ];
};

export const GET_RESOURCES_PEOPLE_COMPANY_CUD_ACTIONS = () => {
  const wsOrgResource = getWSOrgResource();

  return [
    { action: RBAC_ACTIONS.assignees.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.assignees.new, resource: wsOrgResource },
    { action: RBAC_ACTIONS.assignees.delete, resource: wsOrgResource }
  ];
};

export const GET_TIMELINE_RBAC_PAYLOAD = () => {
  const wsOrgPropertyResource = getWSOrgPropertyResource();
  const wsOrgResource = getWSOrgResource();
  return [
    {
      action: RBAC_ACTIONS.fields.new,
      resource: wsOrgPropertyResource
    },
    { action: RBAC_ACTIONS.cropzone.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropcycle.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropzone.new, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropcycle.new, resource: wsOrgResource }
  ];
};

export const GET_NEW_ORG_SEASONS_ACTIONS = () => {
  const wsOrgResource = getWSOrgResource();
  return [
    { action: RBAC_ACTIONS.cropcycle.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropzone.write, resource: wsOrgResource }
  ];
};

export const GET_ALL_FIELD_RBAC_PAYLOAD = () => {
  const wsOrgPropertyResource = getWSOrgPropertyResource();

  return [
    {
      action: RBAC_ACTIONS.fields.new,
      resource: wsOrgPropertyResource
    },
    {
      action: RBAC_ACTIONS.fields.delete,
      resource: wsOrgPropertyResource
    },
    {
      action: RBAC_ACTIONS.fields.write,
      resource: wsOrgPropertyResource
    }
  ];
};

export const GET_CREATE_FIELD_RBAC_PAYLOAD = () => {
  const wsOrgPropertyResource = getWSOrgPropertyResource();
  return [
    {
      action: RBAC_ACTIONS.fields.new,
      resource: wsOrgPropertyResource
    }
  ];
};

const GET_RESOURCE_CUD_ACTION = ({ type }: any) => {
  const rbacOperationsMap: any = {
    1: GET_RESOURCES_PRODUCT_CUD_ACTIONS,
    2: () => [], //for fertilizer
    3: GET_RESOURCES_EQUIPMENTS_CUD_ACTIONS,
    4: GET_RESOURCES_PEOPLE_COMPANY_CUD_ACTIONS,
    5: GET_RESOURCES_PEOPLE_COMPANY_CUD_ACTIONS,
    6: GET_RESOURCES_BUILDINGS_CUD_ACTIONS
  };
  return type ? rbacOperationsMap[type]() : GET_RESOURCES_PRODUCT_CUD_ACTIONS();
};

const GET_PROPERTY_LIST_RBAC_ACTION = () => {
  const wsOrgResource = getWSOrgResource();
  return [
    {
      action: RBAC_ACTIONS.property.new,
      resource: wsOrgResource
    }
  ];
};

export const CROP_CYCLE_MAP_TABLE_VIEW = () => {
  const wsOrgPropertyResource = getWSOrgPropertyResource();
  const wsOrgResource = getWSOrgResource();
  return [
    {
      action: RBAC_ACTIONS.fields.new,
      resource: wsOrgPropertyResource
    },
    { action: RBAC_ACTIONS.cropzone.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropcycle.write, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropcycle.new, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropzone.delete, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropzone.read, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropcycle.read, resource: wsOrgResource },
    { action: RBAC_ACTIONS.cropzone.new, resource: wsOrgResource }
  ];
};

const GET_CROP_CYCLE_TABLE_RBAC_ACTION = () => {
  return [...CROP_CYCLE_MAP_TABLE_VIEW()];
};
const GET_CROP_CYCLE_MAP_RBAC_ACTION = () => {
  return [...CROP_CYCLE_MAP_TABLE_VIEW()];
};

export const GET_PROPERTY_PAGE_RBAC_PAYLOAD = ({ workspace, propertyId }: any) => {
  return [
    {
      action: RBAC_ACTIONS.fields.read,
      resource: `crn:workspace:${workspace}/org/${getOrgIdFromUrl()}/property/${propertyId}`
    },
    {
      action: RBAC_ACTIONS.cropzone.read,
      resource: `crn:workspace:${workspace}/org/${getOrgIdFromUrl()}`
    },
    {
      action: RBAC_ACTIONS.cropcycle.read,
      resource: `crn:workspace:${workspace}/org/${getOrgIdFromUrl()}`
    },
    {
      action: RBAC_ACTIONS.property.write,
      resource: `crn:workspace:${workspace}/org/${getOrgIdFromUrl()}/property/${propertyId}`
    }
  ];
};

const GET_EDIT_FIELD_RBAC_PAYLOAD = () => {
  const wsOrgPropertyResource = getWSOrgPropertyResource();
  return [{ action: RBAC_ACTIONS.fields.delete, resource: wsOrgPropertyResource }];
};
const GET_EDIT_PROPERTY_RBAC_PAYLOAD = () => {
  const wsOrgPropertyResource = getWSOrgPropertyResource({ propertyKey: EDIT_FARM_ROUTE });
  return [{ action: RBAC_ACTIONS.property.delete, resource: wsOrgPropertyResource }];
};
const GET_MANAGE_REGION_RBAC_PAYLOAD = () => {
  const wsOrgPropertyResource = getWSOrgPropertyResource();
  const wsOrgResource = getWSOrgResource();

  return [
    {
      action: RBAC_ACTIONS.fields.write,
      resource: wsOrgPropertyResource
    },
    {
      action: RBAC_ACTIONS.fields.delete,
      resource: wsOrgPropertyResource
    },
    {
      action: RBAC_ACTIONS.fields.new,
      resource: wsOrgResource
    },
    {
      action: RBAC_ACTIONS.cropzone.write,
      resource: wsOrgResource
    },
    {
      action: RBAC_ACTIONS.cropzone.new,
      resource: wsOrgResource
    },
    {
      action: RBAC_ACTIONS.cropcycle.new,
      resource: wsOrgResource
    }
  ];
};

export const PERMISSIONS_METADATA: any = {
  '/app/landing-organization/:id/property/:farmId/regions/manage-regions':
    GET_MANAGE_REGION_RBAC_PAYLOAD,
  '/app/landing-organization/:id/property/:farmId/all-regions': GET_MANAGE_REGION_RBAC_PAYLOAD,
  '/app/landing-organization/:id/property/:farmId/edit-field/:fieldId': GET_EDIT_FIELD_RBAC_PAYLOAD,
  '/app/landing-organization/:id/property/:farmId/region/:parentRegionId/edit-field/:fieldId':
    GET_EDIT_FIELD_RBAC_PAYLOAD,
  '/app/landing-organization/:id/property/:farmId/timeline': GET_TIMELINE_RBAC_PAYLOAD,
  '/app/landing-organization/:id/property/:farmId/table-view': GET_CROP_CYCLE_TABLE_RBAC_ACTION,
  '/app/landing-organization/:id/property/:farmId/map-view': GET_CROP_CYCLE_MAP_RBAC_ACTION,
  '/app/landing-organization/:id/property/:farmId/all-fields': GET_ALL_FIELD_RBAC_PAYLOAD,
  '/app/landing-organization/:id/property/:farmId/archived-fields': GET_ALL_FIELD_RBAC_PAYLOAD,
  '/app/landing-organization/:id/property/:farmId/create-fields': GET_CREATE_FIELD_RBAC_PAYLOAD,
  '/app/landing-organization/:id/resources/:type': GET_RESOURCE_CUD_ACTION,
  '/app/landing-organization/:id/resources/:type/generic-items': GET_RESOURCE_CUD_ACTION,
  '/app/landing-organization/:id/resources/:type/generic-items/:id': GET_RESOURCE_CUD_ACTION,
  '/app/landing-organization/:id/resources/:type/generic-items/create': GET_RESOURCE_CUD_ACTION,
  '/app/landing-organization/:id/resources/:type/seeds-variety': GET_RESOURCE_CUD_ACTION,
  '/app/landing-organization/:id/resources/:type/seeds-variety/:id': GET_RESOURCE_CUD_ACTION,
  '/app/landing-organization/:id/resources/:type/seeds-variety/create': GET_RESOURCE_CUD_ACTION,
  '/app/landing-organization/:id/org-seasons': GET_NEW_ORG_SEASONS_ACTIONS,
  '/app/landing-organization/:id/edit-farm/:farmId': GET_EDIT_PROPERTY_RBAC_PAYLOAD,
  '/app/landing-organization/:id/resources': GET_RESOURCE_CUD_ACTION,
  '/app/landing-organization/:id': GET_PROPERTY_LIST_RBAC_ACTION,
  '/app/deep-actions/create-field': GET_ALL_FIELD_RBAC_PAYLOAD,
  '/app/deep-actions/draw-field': GET_EDIT_FIELD_RBAC_PAYLOAD,
  '/app/deep-actions/edit-field': GET_EDIT_FIELD_RBAC_PAYLOAD,
  '/app/deep-actions/create-property': GET_EDIT_PROPERTY_RBAC_PAYLOAD,
  '/app/deep-actions/edit-property': GET_EDIT_PROPERTY_RBAC_PAYLOAD,
  '/app/deep-actions/property-season': GET_CROP_CYCLE_TABLE_RBAC_ACTION
};
