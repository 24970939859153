import get from 'lodash/get';

import { request } from '../../utils/axios';
import { getHeaders } from '../../utils/commonMethods';
import {
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_ERROR,
  FETCH_ORGANIZATIONS_LOADING,
  SET_SELECTED_ORG,
  SET_SELECTED_PROPERTY,
  SET_ORGS_DETAILS,
  CREATE_ORG_LOADING,
  CREATE_ORG_SUCCESS,
  CREATE_ORG_ERROR,
  CREATE_PROPERTY_SUCCESS,
  CREATE_PROPERTY_ERROR,
  EDIT_PROPERTY_SUCCESS,
  EDIT_PROPERTY_ERROR,
  PROPERTY_LOADING,
  TOGGLE_HEADER,
  TOGGLE_DEEP_ACTION,
  SET_SELECTED_SEASONS,
  DELETE_PROPERTY_BY_ID_SUCCESS,
  DELETE_PROPERTY_BY_ID_LOADING,
  DELETE_PROPERTY_BY_ID_ERROR,
  DELETE_SEASON_BY_ID_SUCCESS,
  DELETE_SEASON_BY_ID_ERROR,
  DELETE_SEASON_BY_ID_LOADING,
  UPDATE_ORGANIZATION_LOADING,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION_LOADING,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION_RESET,
  UPLOAD_ORGANIZATION_AVATAR_LOADING,
  UPLOAD_ORGANIZATION_AVATAR_SUCCESS,
  UPLOAD_ORGANIZATION_AVATAR_ERROR,
  REMOVE_ORGANIZATION_AVATAR_LOADING,
  REMOVE_ORGANIZATION_AVATAR_SUCCESS,
  REMOVE_ORGANIZATION_AVATAR_ERROR,
  EDIT_SEASON_LOADING,
  EDIT_SEASON_SUCCESS,
  EDIT_SEASON_ERROR,
  EDIT_ORG_LOADING,
  EDIT_ORG_SUCCESS,
  EDIT_ORG_ERROR,
  FETCH_SEASON_BY_ID_SUCCESS,
  FETCH_SEASON_BY_ID_ERROR,
  FETCH_SEASON_BY_ID_LOADING,
  SET_WHATS_NEW_ISVISIBLE,
  HAVE_CONTRACT_FOR_ORG,
  SET_CONTRACT_FOR_ORG
} from './types';

export function fetchOrganizationsLoading() {
  return {
    type: FETCH_ORGANIZATIONS_LOADING
  };
}

export function fetchOrgsSuccess(orgs) {
  return {
    type: FETCH_ORGANIZATIONS_SUCCESS,
    payload: orgs
  };
}
export function fetchOrgsError(error) {
  return {
    type: FETCH_ORGANIZATIONS_ERROR,
    error
  };
}

export function deletePropertyByIdLoading(value) {
  return {
    type: DELETE_PROPERTY_BY_ID_LOADING,
    payload: value
  };
}
export function deletePropertyByIdSuccess(value) {
  return {
    type: DELETE_PROPERTY_BY_ID_SUCCESS,
    payload: value
  };
}
export function deletePropertyByIdError(error) {
  return {
    type: DELETE_PROPERTY_BY_ID_ERROR,
    payload: error
  };
}
export function deleteSeasonByIdLoading(value) {
  return {
    type: DELETE_SEASON_BY_ID_LOADING,
    payload: value
  };
}
export function deleteSeasonByIdSuccess(value) {
  return {
    type: DELETE_SEASON_BY_ID_SUCCESS,
    payload: value
  };
}
export function deleteSeasonByIdError(error) {
  return {
    type: DELETE_SEASON_BY_ID_ERROR,
    payload: error
  };
}

export function setSelectedSeasons(seasons) {
  return {
    type: SET_SELECTED_SEASONS,
    payload: seasons
  };
}

export function setCurrentOrgId(org) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_ORG, payload: org });
  };
}
export function setCurrentProp(property) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_PROPERTY, payload: property });
  };
}

export function setOrgsDetails(orgs) {
  return (dispatch) => {
    dispatch({ type: SET_ORGS_DETAILS, payload: orgs });
  };
}

export function createOrgLoading(value) {
  return {
    type: CREATE_ORG_LOADING,
    payload: value
  };
}
export function createOrgSuccess(orgs) {
  return {
    type: CREATE_ORG_SUCCESS,
    payload: orgs
  };
}
export function createOrgError(error) {
  return {
    type: CREATE_ORG_ERROR,
    error
  };
}

export function editOrgLoading(value) {
  return {
    type: EDIT_ORG_LOADING,
    payload: value
  };
}
export function editOrgSuccess(orgs) {
  return {
    type: EDIT_ORG_SUCCESS,
    payload: orgs
  };
}
export function editOrgError(error) {
  return {
    type: EDIT_ORG_ERROR,
    error
  };
}

export const fetchOrgs = () => {
  return (dispatch) => {
    dispatch(fetchOrganizationsLoading());
    return request
      .get(`/v2/orgs?fields=seasons,properties,total_area`, {
        headers: getHeaders()
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchOrgsSuccess(res.data.content));
        return res;
      })
      .catch((error) => {
        dispatch(fetchOrgsError(error));
      });
  };
};

export const toggleDeepAction = (status) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_DEEP_ACTION, payload: status });
  };
};

export const toggleHeader = (status) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_HEADER, payload: status });
  };
};

export function propertyLoading(loading) {
  return {
    type: PROPERTY_LOADING,
    payload: loading
  };
}
export const saveCreatedPropertySuccess = (properties) => {
  return (dispatch) => {
    dispatch({ type: CREATE_PROPERTY_SUCCESS, payload: properties });
  };
};

export const saveEditedPropertySuccess = (properties) => {
  return (dispatch) => {
    dispatch({ type: EDIT_PROPERTY_SUCCESS, payload: properties });
  };
};

export const saveCreatedPropertyError = (error) => {
  return {
    type: CREATE_PROPERTY_ERROR,
    error
  };
};

export const saveEditedPropertyError = (error) => {
  return {
    type: EDIT_PROPERTY_ERROR,
    error
  };
};

export const saveCreatedProperty = (property) => {
  return (dispatch) => {
    dispatch(propertyLoading(true));
    return request({
      method: 'post',
      url: `/v2/properties`,
      headers: getHeaders(),
      data: property
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(saveCreatedPropertySuccess(res.data));
        dispatch(propertyLoading(false));
        return res;
      })
      .catch((error) => {
        dispatch(saveCreatedPropertyError(error));
        return Promise.reject(error);
      });
  };
};

export const saveEditedProperty = (property) => {
  return (dispatch) => {
    dispatch(propertyLoading(true));
    return request({
      method: 'put',
      url: `/v2/properties/${property.id}`,
      headers: getHeaders(),
      data: property
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(saveEditedPropertySuccess(res.data));
        dispatch(propertyLoading(false));
        return res;
      })
      .catch((error) => {
        dispatch(saveEditedPropertyError(error));
        return Promise.reject(error);
      });
  };
};

export const createOrg = (org) => {
  return (dispatch) => {
    dispatch(createOrgLoading(true));
    return request({
      method: 'post',
      url: `/v2/orgs`,
      headers: getHeaders(),
      data: org
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(createOrgSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(createOrgError(error));
        return Promise.reject(error);
      });
  };
};

export const editOrganization = (org) => {
  return (dispatch) => {
    dispatch(editOrgLoading(true));
    return request({
      method: 'put',
      url: `/v2/orgs/${org.id}`,
      headers: getHeaders(),
      data: org
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(editOrgSuccess(res));
        return res;
      })
      .catch((error) => {
        dispatch(editOrgError(error));
        return Promise.reject(error);
      });
  };
};

export const deletePropertyById = (orgId, propertyId) => {
  return (dispatch) => {
    dispatch(deletePropertyByIdLoading(true));
    return request
      .delete(`/v2/properties/${propertyId}`, {
        headers: getHeaders()
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(deletePropertyByIdSuccess({ orgId, propertyId }));
        return res;
      })
      .catch((error) => {
        dispatch(deletePropertyByIdError(error));
        return Promise.reject(error);
      });
  };
};
export const deleteSeasonById = (orgId, seasonId) => {
  return (dispatch) => {
    dispatch(deleteSeasonByIdLoading(true));
    request
      .delete(`/v2/seasons/${seasonId}`, {
        headers: getHeaders()
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(deleteSeasonByIdSuccess({ orgId, seasonId }));
        return res;
      })
      .catch((error) => {
        dispatch(deleteSeasonByIdError(error));
      });
  };
};

export const updateOrganizationLoading = () => {
  return {
    type: UPDATE_ORGANIZATION_LOADING
  };
};

export const updateOrganizationSuccess = (payload) => {
  return {
    type: UPDATE_ORGANIZATION_SUCCESS,
    payload
  };
};

export const updateOrganizationError = (error) => {
  return {
    type: UPDATE_ORGANIZATION_ERROR,
    error
  };
};

export const updateOrganization = (org) => {
  return (dispatch) => {
    dispatch(updateOrganizationLoading());
    request({
      method: 'put',
      url: `/v2/orgs/${org.id}`,
      headers: getHeaders(),
      data: org
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(updateOrganizationSuccess(res));
      })
      .catch((error) => {
        dispatch(updateOrganizationError(error));
      });
  };
};

export const deleteOrganizationReset = () => {
  return {
    type: DELETE_ORGANIZATION_RESET
  };
};
export const deleteOrganizationLoading = () => {
  return {
    type: DELETE_ORGANIZATION_LOADING
  };
};

export const deleteOrganizationSuccess = (payload) => {
  return {
    type: DELETE_ORGANIZATION_SUCCESS,
    payload
  };
};

export const deleteOrganizationError = (error) => {
  return {
    type: DELETE_ORGANIZATION_ERROR,
    error
  };
};

export const deleteOrganization = (org) => {
  return (dispatch) => {
    dispatch(deleteOrganizationLoading());
    return request({
      method: 'delete',
      url: `/v2/orgs/${org.id}`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(deleteOrganizationSuccess(org));
        return res;
      })
      .catch((error) => {
        dispatch(deleteOrganizationError(error));
      });
  };
};

export const uploadOrganizationAvatarLoading = () => {
  return {
    type: UPLOAD_ORGANIZATION_AVATAR_LOADING
  };
};

export const uploadOrganizationAvatarSuccess = (payload) => {
  return {
    type: UPLOAD_ORGANIZATION_AVATAR_SUCCESS,
    payload
  };
};

export const uploadOrganizationAvatarError = (error) => {
  return {
    type: UPLOAD_ORGANIZATION_AVATAR_ERROR,
    error
  };
};

export const uploadOrganizationAvatar = (org, file) => {
  return (dispatch) => {
    dispatch(uploadOrganizationAvatarLoading());
    const data = new FormData();
    data.append('file', file);
    return request({
      method: 'post',
      url: `/v2/orgs/${org.id}/picture`,
      headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' },
      data
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(uploadOrganizationAvatarSuccess({ org, res }));
      })
      .catch((error) => {
        dispatch(uploadOrganizationAvatarError(error));
      });
  };
};

export const removeOrganizationAvatarLoading = () => {
  return {
    type: REMOVE_ORGANIZATION_AVATAR_LOADING
  };
};

export const removeOrganizationAvatarSuccess = (payload) => {
  return {
    type: REMOVE_ORGANIZATION_AVATAR_SUCCESS,
    payload
  };
};

export const removeOrganizationAvatarError = (error) => {
  return {
    type: REMOVE_ORGANIZATION_AVATAR_ERROR,
    error
  };
};

export const deleteOrganizationAvatar = (org) => {
  return (dispatch) => {
    dispatch(removeOrganizationAvatarLoading());
    request({
      method: 'delete',
      url: `/v2/orgs/${org.id}/picture`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(removeOrganizationAvatarSuccess(org));
      })
      .catch((error) => {
        dispatch(removeOrganizationAvatarError(error));
      });
  };
};

const propertyDetailsCache = {}
export const getPropertyDetailsById = async (propId) => {
  if(propertyDetailsCache[propId]) return propertyDetailsCache[propId]
  
  propertyDetailsCache[propId] = request.get(`/v2/properties/${propId}`, {
    headers: getHeaders()
  });
  return propertyDetailsCache[propId]
};

export const editSeasonLoading = () => {
  return {
    type: EDIT_SEASON_LOADING
  };
};

export const editSeasonSuccess = (payload) => {
  return {
    type: EDIT_SEASON_SUCCESS,
    payload
  };
};

export const editSeasonError = (error) => {
  return {
    type: EDIT_SEASON_ERROR,
    error
  };
};

export const editSeason = (season) => {
  return (dispatch) => {
    dispatch(editSeasonLoading());
    request({
      method: 'put',
      url: `/v2/seasons/${season.id}`,
      headers: getHeaders(),
      data: {
        org_id: season.orgId,
        name: season.name,
        start_date: season.startDate,
        end_date: season.endDate,
        crop: {
          id: season.cropId
        }
      }
    })
      .then((res) => {
        if (res.error) {
          dispatch(editSeasonError(res.error.response.data));
        }
        if (season.fieldDetails.length > 0) {
          const seasonId = get(res, 'data.id');
          request({
            method: 'post',
            url: `/v2/seasons/${seasonId}/land/updates`,
            headers: getHeaders(),
            data: { updates: season.fieldDetails }
          })
            .then((response) => {
              if (response.error) {
                dispatch(editSeasonError(response.error.response.data));
              }

              dispatch(editSeasonSuccess(response.data));
            })
            .catch((err) => {
              dispatch(editSeasonError(err.response.data));
            });
        } else {
          dispatch(editSeasonSuccess(res.data));
        }
      })
      .catch((error) => {
        dispatch(editSeasonError(error.response.data));
      });
  };
};

export function fetchSeasonByIdLoading() {
  return {
    type: FETCH_SEASON_BY_ID_LOADING
  };
}

export function fetchSeasonByIdSuccess(season) {
  return {
    type: FETCH_SEASON_BY_ID_SUCCESS,
    payload: season
  };
}
export function fetchSeasonByIdError(error) {
  return {
    type: FETCH_SEASON_BY_ID_ERROR,
    error
  };
}

export const fetchSeasonById = (id) => {
  return request.get(`/v2/seasons/${id}`, {
    headers: getHeaders()
  });
};

export const setWhatsNewIsVisible = (isVisible) => {
  return {
    type: SET_WHATS_NEW_ISVISIBLE,
    payload: isVisible
  };
};

export const setHaveContractForOrg = (haveContract) => {
  return {
    type: HAVE_CONTRACT_FOR_ORG,
    payload: haveContract
  };
};

export const setContractForOrg = (Contract) => {
  return {
    type: SET_CONTRACT_FOR_ORG,
    payload: Contract
  };
};
