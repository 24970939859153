import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import get from 'lodash/get';
import tokml from 'tokml';
import shpwrite from "@mapbox/shp-write";
import { Analytics } from 'utils/analytics/analytics';
import { fieldsToAreaArray } from 'utils/commonMethods';

interface FeatureCollection {
  type: "FeatureCollection";
  features: Feature[];
}

interface Feature {
  geometry: any[];
  properties: Object;
  type: "Feature";
}

/**
 * collect geometry data
 * @param selectedFields
 * @returns
 */
const getFeatures = (selectedFields: any) => {
  const features = [];
  for (const area of selectedFields) {
    if (area.geometry) {
      const properties = get(area, 'properties', {});
      properties.name = area.name;
      properties.declared_area = area.declared_area;
      properties.id = area.id;
      properties.event_date = area.event_date;
      features.push({
        type: 'Feature',
        properties,
        geometry: get(area, 'geometry', {}),
      });
    }
  }
  return features;
};

/**
 *
 * @param {*} zip
 * @param {*} property
 * @param {*} data
 * generate geojson file
 */
const getGeojsonFile = (zip: any, property: any, data: any) => {
  zip.file(`${property.name}.geojson`, JSON.stringify(data, null, 4));
};

const filterUndefinedProperties = (featureCollection: FeatureCollection): FeatureCollection => {

  return {
    type: "FeatureCollection",
    features: featureCollection.features.map((feature) => {
      const propertiesEntries = Object.entries(feature.properties);
      const filteredProperties = propertiesEntries.filter(([key, value]) => value !== undefined);
      return {
        ...feature,
        properties: Object.fromEntries(filteredProperties)
      }
    })
  }
}

/**
 *
 * @param {*} zip
 * @param {*} property
 * @param {*} data
 * generate geojson file
 */
const getKmlFile = (zip: any, property: any, data: any) => {
  const filteredData = filterUndefinedProperties(data);
  zip.file(`${property.name}.kml`, tokml(filteredData, { simplestyle: false }));
};

/**
 *
 * @param {*} property
 * @param {*} data
 * generate geojson file
 */
const getShapeFile = async (property: any, data: any) => {
  const options: any = {
    folder: 'folder',
    filename: 'test',
    outputType: 'blob',
    types: {
      point: 'mypoints',
      polygon: 'mypolygons',
      line: 'mylines',
    },
  };
  // The library only supports the usage of either Polygons or MultiPolygons, so we switch all Polygons to MultiPolygons
  const formatedFeatures = data.features?.map((feature: any) => {
    if (feature.geometry.type === "MultiPolygon") {
      return feature;
    }
    return {
      ...feature,
      geometry: {
        type: "MultiPolygon",
        coordinates: [feature.geometry.coordinates]
      }
    }
  }) ?? [];
  const newData = {
    ...data,
    features: formatedFeatures
  };
  shpwrite.zip(newData, options).then((blob) => {
    saveAs(blob as Blob, `${property.name}.zip`)
  })
};

/**
 *
 * @param zip
 * @param property
 * @param labels
 */
const downloadZipManually = (zip: any, property: any) => {
  let error: any = null;
  zip
    .generateAsync({ type: 'blob' })
    .then((content: any) => {
      saveAs(content, `${property.name}.zip`);
    })
    .catch((err: any) => {
      error = err;
    });
  return error;
};

/**
 *
 * @param {*} selectedFields
 * @param {*} property
 * @param {*} t
 * download shape files
 */
const downloadFields = (selectedFields: any, property: any, labels: any, downloadAs = '') => {
  let error: any = null;
  if (selectedFields.length > 0) {
    try {
      Analytics.track('Fields - Downloaded field', {
        areaInHectars: fieldsToAreaArray(selectedFields)
      })
      const features = getFeatures(selectedFields);
      if (features.length > 0) {
        const data = {
          type: 'FeatureCollection',
          features,
        };
        const zip = new JSZip();
        switch (downloadAs) {
          case 'kml':
            getKmlFile(zip, property, data);
            downloadZipManually(zip, property);
            break;
          case 'shape':
            getShapeFile(property, data);
            break;
          default:
            getGeojsonFile(zip, property, data);
            downloadZipManually(zip, property);
        }
      } else {
        error = new Error(labels.no_geometry_found);
      }
    } catch (err) {
      error = err;
    }
  } else {
    error = new Error(labels.no_field_selected);
  }
  return error;
};

export default downloadFields;
