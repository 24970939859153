import axios from 'axios';

// replace below key with your actual key from ipapi account
const accessKey = '4377f0493d66ec951a3f4782bf4c0b11';

/**
 * please refer: https://www.ipify.org/
 */
const getPublicIP = async (): Promise<string | undefined> => {
  const response = await axios.get('https://api.ipify.org');
  return response.data;
};

/**
 * please  refer: https://ipapi.com/documentation
 */
export const getUserLocationDetails = async (): Promise<any | undefined> => {
  try {
    const myPublicIP = await getPublicIP();
    const url = `https://api.ipapi.com/${myPublicIP}?access_key=${accessKey}`;
    const response = await axios.get(url);
    sessionStorage.setItem('ipapi-details', JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};

export const getIpApiDetailsFromSession = () => {
  const details = sessionStorage.getItem('ipapi-details');
  if (details) {
    const result = JSON.parse(details);
    return {
      latitude: result.latitude,
      longitude: result.longitude,
      country_name: result.country_name,
      country_code: result.country_code && result.country_code.toLowerCase()
    };
  }

  return null;
};
