import { FieldModel, RegionModel, RegionNameMapViewModel } from './archiving.model';

interface Version {
  start_date: string;
  end_date: string | null;
}
/**
 * Check if there is a temporal gap between the version at `currentIndex` and the previous version.
 * @param history Array of field versions, sorted in descending order (current version = index 0)
 * @param currentIndex Index to check for temporal gaps
 * @returns true if there is a temporal gap, false otherwise
 */
export function checkTemporalGap(history: Version[], currentIndex: number) {
  const currentVersion = history?.[currentIndex];
  const prevVersion = history?.[currentIndex + 1];

  if (typeof currentVersion?.start_date === 'string' && typeof prevVersion?.end_date === 'string') {
    return currentVersion.start_date > prevVersion.end_date;
  }
  return false;
}

export function getFieldWithParentName(field: FieldModel, regionNames: RegionNameMapViewModel) {
  const parentName = regionNames[field.parent_region_id];
  const name = (parentName ? parentName + ' / ' : '') + field.name;

  return {
    ...field,
    name
  };
}

export function getRegionChildrenName(children: RegionModel[], parentName: string = '') {
  const regionNames: RegionNameMapViewModel = {};
  for (let region of children) {
    const name = (parentName ? parentName + ' / ' : '') + region.name;
    regionNames[region.id] = name;
    Object.assign(regionNames, getRegionChildrenName(region.children_region, name));
  }
  return regionNames;
}
