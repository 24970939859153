import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import qs from 'querystring';

import { AppConstants } from '../../utils/app.constants';
import { validateUUID } from 'utils/commonMethods';

export const isUserLoaded = (state: any) => !!(state?.user?.userProfile?.id);

export const getUserPermissions = (state: any) => state.user;

export const getUserPropertiesPermissions = (state: any) => {
  return state.user.userProfile.authorities.filter(
    (item: any) => item && item.context === 'PROPERTY'
  );
};

export const getUserOrgsPermissions = (state: any) => {
  return state.user.userProfile.authorities.filter(
    (item: any) => item && item.context === 'ORGANIZATION'
  );
};

export const getPropertyPermissionById = createSelector(
  getUserPropertiesPermissions,
  (_: any, props: any) => props.propertyId,
  (permissions, propertyId) => {
    let permission;
    permission = permissions?.find(
      (propertyPermission: any) => propertyPermission.id === propertyId
    );
    if (!isEmpty(permission)) {
      permission = permission.permissions.find((item: any) => item.name === 'INFORMATION');
    }
    return get(permission, 'scope', 'READ');
  }
);

export const getOrgPermissionById = createSelector(
  getUserOrgsPermissions,
  (_: any, props: any) => props.orgId,
  (orgPermissions, orgId) => {
    return orgPermissions?.find((orgPermission: any) => orgPermission.id === orgId) || {};
  }
);

export const getPermissionDetailsByOrgId = createSelector(
  getOrgPermissionById,
  (orgPermissions = {}) => {
    const obj: any = {};
    if (!isEmpty(orgPermissions)) {
      orgPermissions.permissions.forEach((item: any) => {
        if (isEmpty(obj[item.name]) || (item.scope === 'WRITE' && !isEmpty(obj[item.name]))) {
          obj[item.name] = item.scope;
        }
      });
    }
    return obj;
  }
);

export const getSeasonPermissionByOrgId = createSelector(
  getOrgPermissionById,
  (orgPermissions = {}) => {
    let seasonPermission =
      !isEmpty(orgPermissions) &&
      orgPermissions.permissions.find((item: any) => item.name === 'SEASONS');
    if (seasonPermission) {
      seasonPermission = seasonPermission.scope || 'NONE';
    }
    return seasonPermission || 'NONE';
  }
);

export const getCurrentUserType = createSelector(getUserPermissions, (loggedInUserDetails) => {
  return get(loggedInUserDetails, 'userProfile.type', '');
});

export const getDateFormat = (state: any) => {
  return state.user.userProfile.date_format || AppConstants.DATE_FORMATS_SETTINGS[0];
};

export const getCurrentWorkspaceFromURL = () => {
  const locationSearch = window.location.search.replace('?', '');
  const query = qs.parse(locationSearch);
  const qsWorkspace: any = query.workspace;
  if (validateUUID(qsWorkspace)) {
    return qsWorkspace;
  }
  return null;
};

export const getCurrentWorkspace = (state: any) => {
  const workspaceFromURL = getCurrentWorkspaceFromURL();
  const workspaceFromOrg = state?.orgs?.orgById?.workspace_id
  const licensingAccountsLimit = 5;
  let workspaceFromExtendedAttribute = undefined;
  let firstAvailableWorkspace = undefined;
  const user = get(state, 'user.userProfile', {});
  if (user.id) {
    workspaceFromExtendedAttribute = get(
      state.user.userProfile.json_extended_attributes,
      'current_workspace'
    );

    firstAvailableWorkspace =
      state.user.userProfile.licensing_accounts.length &&
      state.user.userProfile.licensing_accounts.length < licensingAccountsLimit
        ? state.user.userProfile.licensing_accounts[0].licensing_account_id
        : undefined;
  }

  return workspaceFromURL || workspaceFromOrg || workspaceFromExtendedAttribute || firstAvailableWorkspace;
};


export const getResourcesPermissions = (state: any) => {
  const companyPlanId = get(state, 'header.contractForOrg.plan.id', null)
  const planWithFilter = get(state, 'rollout.flags.PlansWithResourceFilter', "")
  const isPlanWithFilter = planWithFilter.includes(companyPlanId)

  if(isPlanWithFilter){
    const userProperties = get(state, 'propertiesList.data.entities.properties', {})
    return Object.keys(userProperties)
  }

  return null
};
