import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { notification } from 'antd';
import { getLocationFromBrowser } from './location';
import { getPropertyDetailsById } from '../redux/actions';
import { store } from 'redux/reducer/store';

export const getLatLongByPropId = async (
  propertyId,
  setLat,
  setLong,
  DEFAULT_LAT_LONG,
  getAddressFromLatLong = () => {
    return null;
  }
) => {
  const state = store?.getState()
  const property = state?.propertiesList?.data?.entities?.properties?.[propertyId]

  const propertyRes = property ? { data: property } : await getPropertyDetailsById(propertyId);

  const coordinates = get(propertyRes.data, 'reference_point.coordinates');
  if (isEmpty(coordinates)) {
    (async () => {
      try {
        const location = getLocationFromBrowser();
        const { latitude, longitude } = location;
        if (setLat) {
          setLong(longitude);
          setLat(latitude);
        }
        getAddressFromLatLong({ lat: latitude, lng: longitude });
      } catch (err) {
        notification.error({
          message: 'Error',
          description: err.message
        });
      }
    })();
  } else {
    getAddressFromLatLong({ lat: coordinates[1], lng: coordinates[0] });
    if (setLat) {
      setLat(coordinates[1] || DEFAULT_LAT_LONG);
      setLong(coordinates[0] || DEFAULT_LAT_LONG);
    }
  }
  return propertyRes;
};
