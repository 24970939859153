import { iff } from '../../utils/iff';
import { isCreatePropertySessionActive } from '../../utils/getShowStepper';
import { URL_CONSTANTS } from '../../utils/history';

const PAGE_NAME = 'CURRENT_REGION_PAGE_NAME';

export const ASSOCIATE_CROP_REGION = 'ASSOCIATE_CROP_REGION';
export const MANAGE_REGION = 'MANAGE_REGION';
export const CREATE_REGION_AND_FIELD = 'CREATE_REGION_AND_FIELD';

export function setCurrentRegionPage(pageName) {
  sessionStorage.setItem(PAGE_NAME, pageName);
}

export function getCurrentRegionPage() {
  return sessionStorage.getItem(PAGE_NAME) || '';
}

export function clearRegionPage() {
  sessionStorage.removeItem(PAGE_NAME);
}

/**
 *
 * @param {*} params
 * @returns
 */
export function manageRedirection(args) {
  const { orgId, farmId, parentId, newFields, isFildsAdded, navigate } = args;
  const showStepper = isCreatePropertySessionActive();
  return iff(
    showStepper === true,
    () =>
      navigate(URL_CONSTANTS.ALL_REGIONS({ orgId, farmId }), {
        state: {
          parentId,
          newFields,
          isFildsAdded
        }
      }),
    () =>
      navigate(URL_CONSTANTS.MANAGE_REGIONS({ orgId, farmId }), {
        state: {
          parentId,
          newFields,
          isFildsAdded
        }
      })
  );
}
