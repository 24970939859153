export const isProduction = process.env.REACT_APP_envName === 'production';
export const isStaging = process.env.REACT_APP_envName === 'staging';
export const isQA = process.env.REACT_APP_envName === 'qa';
export const isLocal = process.env.REACT_APP_envName === 'local';

export const i18nToMoment = {
  'es-419': 'es',
  'pt-BR': 'pt-br',
  'es-AR': 'es'
};

export const defaultLanguage = 'en'