import { createSelector } from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';

import { cropColors } from '../../utils/cropColor';
import { getAreaByUnit, getUnitText } from '../../utils/getAreaByUnit';

export const getOrgs = (state) => state.orgs || {};

export const getOrganizationById = (state) => {
  return (state.orgs && state.orgs.orgById) || {};
};

export const getCountryOfOrganization = (state) => {
  return (state.orgs && state.orgs.orgById.country) || "BR";
};


export const getOrgsData = (state, { id } = {}) => {
  const data = getOrgs(state).data || [];
  if (data.length && id) {
    return data.filter((d) => d.id === id);
  }
  return data;
};

export const getNoOfOrgs = createSelector(
  getOrgs,
  (orgs) => (orgs.data && Array.isArray(orgs.data) && orgs.data.length) || 0
);

export const getOrgById = createSelector(getOrgsData, (data) => data[0] || {});

export const getOrgDetailsByOrgId = (state, { orgId } = {}) => {
  const data = getOrgs(state).data || [];
  return data.find((d) => d.id === orgId) || {};
};

export const getSelectedOrg = (state) => {
  return getOrgs(state).selectedOrg;
};

export const getSelectedProperty = (state) => {
  return getOrgs(state).selectedProperty;
};

export const getSelectedSeasons = (state) => {
  return getOrgs(state).selectedSeasons;
};

export const getSelectedPropFields = (state) => {
  return getOrgs(state).selectedPropFields;
};

export const isLoading = (state) => {
  return getOrgs(state).loading;
};

export const getError = (state) => {
  return getOrgs(state).error;
};

export const isError = createSelector(
  getError,
  (err) => err !== null && typeof err === 'object' && Object.keys(err) > 0
);

export const isUpdating = createSelector(getOrgs, (data) => data.updating);

export const isUpdatingSuccess = createSelector(getOrgs, (data) => data.updatingSuccess);

export const isUpdateError = createSelector(getOrgs, (data) => data.updatingError);

export const isDeleting = createSelector(getOrgs, (data) => data.deleteOrgLoading);

export const isDeletingError = createSelector(getOrgs, (data) => data.deleteOrgError);

export const isDeletingSuccess = createSelector(getOrgs, (data) => data.deleteOrgSuccess);

export const isAvatarUploading = createSelector(getOrgs, (data) => data.uploadOrgAvatarLoading);

export const isAvatarUploadingError = createSelector(getOrgs, (data) => data.uploadOrgAvatarError);

export const isAvatarUploadingSuccess = createSelector(
  getOrgs,
  (data) => data.uploadOrgAvatarSuccess
);

export const isAvatarRemoving = createSelector(getOrgs, (data) => data.removeOrgAvatarLoading);

export const isAvatarRemovingError = createSelector(getOrgs, (data) => data.removeOrgAvatarError);

export const isAvatarRemovingSuccess = createSelector(
  getOrgs,
  (data) => data.removeOrgAvatarSuccess
);

export const getOrgsDetails = (state) => {
  const orgs = getOrgsData(state);

  return orgs.map((org) => ({
    name: org.name,
    id: org.id,
    address: `${org.address}, ${org.state} - ${org.country} ${org.zip_code}`,
    totalArea: {
      area: getAreaByUnit(org.total_area, org.unit_system),
      unit: getUnitText(org.unit_system)
    },
    noOfProperties: (org.properties && Array.isArray(org.properties) && org.properties.length) || 0,
    crops:
      org.seasons &&
      Array.isArray(org.seasons) &&
      org.seasons.map((s) => ({
        id: s.crop.id,
        name: s.crop.name,
        image: s.crop.image_path
      }))
  }));
};

export const getPropertyById = (id) => {
  const orgs = getOrgs();
  let property = {};
  orgs.forEach((org) => {
    property = org.properties.find((prop) => prop.id === id) || {};
  });
  return property;
};

export const getOrganizations = createSelector(
  getOrgs,
  (organizations) => organizations.data || []
);

export const getPropertiesByOrgId = createSelector(
  getOrganizations,
  (_, props) => props.orgId,
  (organizations, orgId) => {
    const organization = organizations.find((o) => o.id === orgId);
    return (organization && organization.properties) || [];
  }
);

export const getSeasonsByOrgId = createSelector(
  getOrganizations,
  (_, props) => props.orgId,
  (organizations, orgId) => {
    const organization = organizations.find((o) => o.id === orgId);
    return (organization && organization.seasons) || [];
  }
);

export const getSeasonsByOrgnPropertyId = createSelector(
  getOrganizations,
  (_, props) => props.orgId,
  (_, props) => props.propertyId,
  (organizations, orgId, propertyId) => {
    const organization = organizations.find((o) => o.id === orgId);
    const seasons =
      (organization &&
        organization.seasons &&
        organization.seasons.filter(
          (s) => s.season_properties && s.season_properties.find((sp) => sp.id === propertyId)
        )) ||
      [];
    const activeSeasons = seasons.filter((s) => s.active);
    const inActiveSeasons = seasons.filter((s) => !s.active);
    return { seasons, activeSeasons, inActiveSeasons };
  }
);

export const getFieldsBySeasonsAndPropId = (state, { orgId, seasonId, propId }) => {
  const orgData = find(get(state, 'orgs.data'), { id: orgId }) || {};
  const selectedSeason = find(orgData.seasons, { id: seasonId }) || {};
  const selectedProperty = find(selectedSeason.season_properties, { id: propId }) || {};
  const fillColor = cropColors(get(selectedSeason, 'crop.wk_slug', '').toLowerCase());
  selectedProperty.season_fields = (selectedProperty.season_fields || []).map((field) => ({
    ...field,
    fillColor
  }));
  return selectedProperty.season_fields || [];
};

export const getOrgWorkspaceId = (state) => state?.orgs?.orgById?.workspace_id || {};
