import {
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_LOADING,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_WORKSPACE_SUCCESS,
  FETCH_USER_WORKSPACE_ERROR,
  FETCH_USER_WORKSPACE_LOADING,
  FETCH_CURRENT_WORKSPACE_SUCCESS,
  OPEN_USER_LOCATION_MODAL,
  CLOSE_USER_LOCATION_MODAL
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  userProfile: {
    authorities: []
  },
  defaultLicensingAccountInfo: {},
  currentworkspaceInfo: {},
  loadingLicensing: false,
  errorLicensing: null,
  successLicensing: false,
  isLocationModalOpen: false
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        userProfile: action.payload
      };
    case FETCH_USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    case FETCH_USER_WORKSPACE_LOADING:
      return {
        ...state,
        loadingLicensing: true,
        successLicensing: false,
        errorLicensing: null
      };
    case FETCH_USER_WORKSPACE_SUCCESS:
      return {
        ...state,
        loadingLicensing: false,
        successLicensing: true,
        defaultLicensingAccountInfo: action.payload
      };
    case FETCH_CURRENT_WORKSPACE_SUCCESS:
      return {
        ...state,
        loadingLicensing: false,
        successLicensing: true,
        currentworkspaceInfo: action.payload
      };
    case FETCH_USER_WORKSPACE_ERROR:
      return {
        ...state,
        loadingLicensing: false,
        successLicensing: false,
        errorLicensing: action.payload
      };
    case OPEN_USER_LOCATION_MODAL:
      return {
        ...state,
        isLocationModalOpen: true
      }
    case CLOSE_USER_LOCATION_MODAL:
      return {
        ...state,
        isLocationModalOpen: false
      }

    default:
      return state;
  }
};
