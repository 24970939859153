import {
  GET_ACCOUNT_ROLES_LOADING,
  GET_ACCOUNT_ROLES_SUCCESS,
  GET_ACCOUNT_ROLES_ERROR,
  EDIT_ACCOUNT_ROLES_LOADING,
  EDIT_ACCOUNT_ROLES_SUCCESS,
  EDIT_ACCOUNT_ROLES_ERROR,
  VERIFY_PERMISSIONS_LOADING,
  VERIFY_PERMISSIONS_SUCCESS,
  VERIFY_PERMISSIONS_ERROR,
  GET_ROLES_BY_CONTEXT_LOADING,
  GET_ROLES_BY_CONTEXT_SUCCESS,
  GET_ROLES_BY_CONTEXT_ERROR
} from '../actions/types';
  
  const initialState = {
    loading: false,
    hasLoaded: false,
    error: null,
    success: false,
    data: {},
    accountRoles: [],
    rolesByContext: [],
    allowedAndDeniedPermissions: {},
    accountRolesAcceptanceResponse: {}
  };
  
  export const rbac = (state = initialState, action) => {
    switch (action.type) {
      case GET_ACCOUNT_ROLES_LOADING:
      case VERIFY_PERMISSIONS_LOADING:
      case EDIT_ACCOUNT_ROLES_LOADING:
      case GET_ROLES_BY_CONTEXT_LOADING:
        return {
          ...state,
          success: false,
          loading: true,
          hasLoaded: false
        };
      case GET_ACCOUNT_ROLES_SUCCESS:
        return {
          ...state,
          success: true,
          loading: false,
          error: null,
          accountRoles: action.payload,
          hasLoaded: true
        };
      case GET_ROLES_BY_CONTEXT_SUCCESS:
        return {
          ...state,
          success: true,
          loading: false,
          error: null,
          rolesByContext: action.payload,
          hasLoaded: true
        };
      case VERIFY_PERMISSIONS_SUCCESS:
        return {
          ...state,
          success: true,
          loading: false,
          error: null,
          allowedAndDeniedPermissions: action.payload,
          hasLoaded: true
        };
      case EDIT_ACCOUNT_ROLES_SUCCESS:
        return {
          ...state,
          success: true,
          loading: false,
          error: null,
          accountRolesAcceptanceResponse: action.payload,
          hasLoaded: true
        };
      case GET_ACCOUNT_ROLES_ERROR:
      case VERIFY_PERMISSIONS_ERROR:
      case EDIT_ACCOUNT_ROLES_ERROR:
      case GET_ROLES_BY_CONTEXT_ERROR:
        return {
          ...state,
          success: false,
          loading: false,
          error: action.error,
          hasLoaded: true
        };
      default:
        return state;
    }
  };
  