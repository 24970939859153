import { request } from '../../utils/axios';
import { getLangHeaders, isUK } from '../../utils/commonMethods';
import {
  FETCH_APPS_ERROR,
  FETCH_APPS_LOADING,
  FETCH_APPS_SUCCESS,
  FETCH_APP_CLIENTS_ERROR,
  FETCH_APP_CLIENTS_LOADING,
  FETCH_APP_CLIENTS_SUCCESS,
  FETCH_APP_INFO_ERROR,
  FETCH_APP_INFO_LOADING,
  FETCH_APP_INFO_SUCCESS,
  FETCH_WORKSPACES_APPS_ERROR,
  FETCH_WORKSPACES_APPS_LOADING,
  FETCH_WORKSPACES_APPS_SUCCESS,
  FETCH_WORKSPACES_USER_IS_MEMBER_OF_SUCCESS,
  RESET_FETCH_APPS
} from './types';

export function fetchWorkspacesAppsLoading(value) {
  return {
    type: FETCH_WORKSPACES_APPS_LOADING,
    payload: value
  };
}
export function fetchWorkspacesAppsSuccess(apps) {
  return {
    type: FETCH_WORKSPACES_APPS_SUCCESS,
    payload: apps
  };
}
export function fetchWorkspacesAppsError(error) {
  return {
    type: FETCH_WORKSPACES_APPS_ERROR,
    error
  };
}

export function fetchAppsLoading(value) {
  return {
    type: FETCH_APPS_LOADING,
    payload: value
  };
}
export function fetchAppsSuccess(apps) {
  return {
    type: FETCH_APPS_SUCCESS,
    payload: apps
  };
}
export function fetchWorkspacesUserIsMemberOfSuccess(workspaces) {
  return {
    type: FETCH_WORKSPACES_USER_IS_MEMBER_OF_SUCCESS,
    payload: workspaces
  };
}
export function fetchAppsError(error) {
  return {
    type: FETCH_APPS_ERROR,
    error
  };
}

export function fetchAppInfoLoading(value) {
  return {
    type: FETCH_APP_INFO_LOADING,
    payload: value
  };
}
export function fetchAppInfoSuccess(appInfo) {
  return {
    type: FETCH_APP_INFO_SUCCESS,
    payload: appInfo
  };
}
export function fetchAppInfoError(error) {
  return {
    type: FETCH_APP_INFO_ERROR,
    error
  };
}

export function fetchAppClientsLoading(value) {
  return {
    type: FETCH_APP_CLIENTS_LOADING,
    payload: value
  };
}
export function fetchAppClientsSuccess(appInfo) {
  return {
    type: FETCH_APP_CLIENTS_SUCCESS,
    payload: appInfo
  };
}
export function fetchAppClientsError(error) {
  return {
    type: FETCH_APP_CLIENTS_ERROR,
    error
  };
}

const setWorkspacesUserIsMemberOf = (workspacesApps) => (dispatch) => {
  const workspaceAppsIds = workspacesApps.map((workspaceApp) => workspaceApp.workspace_id);
  dispatch(fetchWorkspacesUserIsMemberOfSuccess(workspaceAppsIds));
};

export const fetchWorkspacesApps = () => async (dispatch) => {
  let langHeader = '';
  if (isUK()) {
    langHeader = 'en-GB';
  }
  dispatch(fetchAppsLoading());
  const urlRequest = `v2/store/workspaces/apps?filter_by_language=false`;
  try {
    const res = await request.get(urlRequest, {
      headers: getLangHeaders(langHeader)
    });
    if (res && res.data) {
      dispatch(fetchAppsSuccess(res.data.content));
      dispatch(setWorkspacesUserIsMemberOf(res.data.content));
      return res.data.content;
    }
  } catch (error) {
    dispatch(fetchAppsError(error));
    return error;
  }
  return null;
};

export const fetchAppsDetails =
  (licensingAccountId = '') =>
  async (dispatch) => {
    let langHeader = '';
    if (isUK()) {
      langHeader = 'en-GB';
    }
    dispatch(fetchAppsLoading());
    let urlRequest = `v2/store/apps`;
    if (licensingAccountId.length > 0) {
      urlRequest = `${urlRequest}?licensing_account_id=${licensingAccountId}&include_clients_info=true`;
    }
    try {
      const res = await request.get(urlRequest, {
        headers: getLangHeaders(langHeader)
      });
      if (res && res.data) {
        dispatch(fetchAppsSuccess(res.data.content));
        return res.data.content;
      }
    } catch (error) {
      dispatch(fetchAppsError(error));
      return error;
    }
    return null;
  };

export function resetFetchApps() {
  return {
    type: RESET_FETCH_APPS
  };
}

export const fetchAppInfoById = (appId) => async (dispatch) => {
  let langHeader = '';
  if (isUK()) {
    langHeader = 'en-GB';
  }
  dispatch(fetchAppInfoLoading());
  try {
    const res = await request.get(`v2/apps/${appId}/info`, {
      headers: getLangHeaders(langHeader)
    });
    if (res && res.data) {
      dispatch(fetchAppInfoSuccess(res.data));
      return res.data;
    }
  } catch (error) {
    dispatch(fetchAppInfoError(error));
    return error;
  }
  return null;
};
