import moment from 'moment';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import { Badge, Col, GeoJsonToSvg, Modal, Row } from 'syngenta-digital-cropwise-react-ui-kit';

import { getAreaByUnit, getUnitText } from '../../utils/getAreaByUnit';
import { getRegionsByIds } from './../../redux/actions/regions';
import { iff } from './../../utils/iff';
import { FontIcon } from './../common/iconComponent';
import { getSortedDates } from './fieldHistoryCard';

/**
 *
 * @param props
 * @returns
 */
export const ModalTitle = (props: any) => {
  const { t } = props;
  return (
    <div className='copy-field-version-modal-title' data-testid='copy-field-version-modal-title'>
      <span>{t('global.map.field_history_card.select_version_to_copy')}</span>
      <span className='copy-field-version-modal-close-icon' onClick={props.onModalClose}>
        <FontIcon name='close-glyph' size={10.5} color='#696F88' />
      </span>
    </div>
  );
};

/**
 *
 * @param props
 * @returns
 */
export const HistoryRow = (props: any) => {
  const {
    setSelectedVersion,
    unitSystem,
    index,
    history,
    dateFormat,
    sortedDates,
    regionNames,
    selectedVersion,
    currentVersion,
    t
  } = props;
  const versionFromDate = moment(history.event_date).format(dateFormat);
  const indexOfCurrentVersion = sortedDates.indexOf(history.event_date);
  const versionToDate = iff(
    indexOfCurrentVersion === sortedDates.length - 1,
    '...',
    moment(sortedDates[indexOfCurrentVersion + 1])
      .subtract(1, 'days')
      .format(dateFormat)
  );

  return (
    <Row
      className={`${'copy-field-version-modal-description-row'} ${iff(
        selectedVersion !== null && history.version_id === selectedVersion.version_id,
        'selected',
        ''
      )}`}
      key={index}
      onClick={() => setSelectedVersion(history)}>
      <Col sm={6} className='copy-field-version-modal-description-row-img'>
        <LazyLoad height={90} width={90}>
          <GeoJsonToSvg geojson={history} width='100%' height='100%' />
        </LazyLoad>
      </Col>
      <Col sm={18} className='copy-field-version-modal-description-row-details'>
        <span className='copy-field-version-modal-description-row-details-name-container'>
          <span className='copy-field-version-modal-description-row-details-name'>
            {history.name}
          </span>
          {moment(currentVersion.event_date).isSame(moment(history.event_date)) && (
            <Badge
              status='success'
              text={t('global.map.field_history_card.current')}
              className='current-version-badge'
              dot={false}
              color='#14803C'
            />
          )}
        </span>

        <span className='copy-field-version-modal-description-row-details-region'>
          {regionNames[history.parent_region_id]}
        </span>
        <span className='copy-field-version-modal-description-row-details-area'>
          {`${getAreaByUnit(history.declared_area, unitSystem)} ${getUnitText(unitSystem)}`}
        </span>
        <span className='copy-field-version-modal-description-row-details-dates'>
          {`${versionFromDate} ${t('common.text.to')} ${versionToDate}`}
        </span>
      </Col>
    </Row>
  );
};

/**
 *
 * @param props
 * @returns
 */
export const ModalContent = (props: any) => {
  const {
    setSelectedVersion,
    fieldHistory,
    unitSystem,
    dateFormat,
    selectedVersion,
    currentVersion,
    t
  } = props;
  const historyWithoutNewVersion = fieldHistory.filter((v: any) => v.isNewVersion !== true);
  const versionDates = historyWithoutNewVersion.map((v: any) => v.event_date);
  const sortedDates = getSortedDates(versionDates).reverse();
  const regionsIds = historyWithoutNewVersion.map((v: any) => v.parent_region_id);
  const [regionNames, setRegionNames] = useState<any>({});

  useEffect(() => {
    async function fetchRegionDetails() {
      try {
        const response = await getRegionsByIds(regionsIds);
        const temp: any = {};
        for (const region of response) {
          temp[region.id] = region.name;
        }
        setRegionNames(temp);
      } catch (error) {
        //eslint-disable-next-line
        console.log(error)
      }
    }

    fetchRegionDetails();
  }, [regionsIds]);

  return (
    <div
      data-testid='copy-field-version-modal-description'
      className='copy-field-version-modal-description'>
      {historyWithoutNewVersion.map((history: any, index: number) => (
        <HistoryRow
          key={index}
          setSelectedVersion={setSelectedVersion}
          unitSystem={unitSystem}
          index={index}
          history={history}
          fieldHistory={fieldHistory}
          dateFormat={dateFormat}
          sortedDates={sortedDates}
          regionNames={regionNames}
          selectedVersion={selectedVersion}
          currentVersion={currentVersion}
          t={t}
        />
      ))}
    </div>
  );
};

/**
 * when users clicks on plus icon to create new version, display this modal
 * @param props
 * @returns
 */
export const CopyFieldVersion = (props: any) => {
  const {
    showCopyFieldVersionModal,
    setShowCopyFieldVersionModal,
    fieldHistory,
    unitSystem,
    dateFormat,
    manageOnHistoryClick,
    eventDate,
    currentVersion,
    t
  } = props;
  const [selectedVersion, setSelectedVersion] = useState<any>(null);

  const onSave = () => {
    manageOnHistoryClick({
      clickedVersion: {
        ...selectedVersion,
        event_date: eventDate
      }
    });
    setShowCopyFieldVersionModal(false);
    setSelectedVersion(null);
  };

  const onModalClose = () => {
    setShowCopyFieldVersionModal(false);
    setSelectedVersion(null);
  };

  return (
    <Modal
      visible={showCopyFieldVersionModal}
      onOk={onSave}
      onCancel={onModalClose}
      okText={t('global.map.field_history_card.copy_version_button')}
      cancelText={t('common.button.cancel')}
      centered={true}
      closable={false}
      width={423}
      okType='primary'
      okButtonProps={{
        style: { background: '#14803C' },
        disabled: iff(selectedVersion === null, true, false)
      }}
      className='copy-field-version-modal'
      data-testid='copy-field-version-modal'>
      <div
        className='copy-field-version-modal-content'
        data-testid='copy-field-version-modal-content'>
        <ModalTitle onModalClose={onModalClose} t={t} />
        <ModalContent
          fieldHistory={fieldHistory}
          setSelectedVersion={setSelectedVersion}
          selectedVersion={selectedVersion}
          unitSystem={unitSystem}
          dateFormat={dateFormat}
          currentVersion={currentVersion}
          t={t}
        />
      </div>
    </Modal>
  );
};
