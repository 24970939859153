import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 16px;
  margin: 0;
`;

export const WarningIcon = styled(WarningOutlined)<{ $error?: boolean }>`
  font-size: 24px;
  color: ${({ $error }) => ($error ? '#eb4b4b' : '#e99921')};
`;

export const CloseIcon = styled(CloseOutlined)`
  cursor: 'pointer';
  transition: opacity 300ms ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

export const Btn = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #14151c;
  border: 1px solid #c2c7d0;
  outline: none;
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover:not(:disabled) {
    border-color: #14151c;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

export const OKBtn = styled(Btn)`
  background-color: #14803c;
  border-color: #14803c;
  color: #ffffff;
  outline: none;

  &:hover:not(:disabled) {
    background-color: #19a04b;
    border-color: #19a04b;
  }
`;
