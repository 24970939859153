import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import { translationWrapper } from '../../utils/commonMethods';
import ZoomOutIcon from '../../public/add_24px.svg';
import ZoomInIcon from '../../public/remove_24px.svg';
import { Button } from 'antd';

interface Props {
  zoomOutClick: () => any;
  zoomInClick: () => any;
}

export const ZoomControls: React.FC<Props> = ({ zoomOutClick, zoomInClick }) => {
  const { t } = translationWrapper(useTranslation(), false);
  return (
    <Row className='map-zoom-btn-container'>
      <Col span={24}>
        <Tooltip
          zIndex={9999999}
          title={t(`global.map.tooltip.zoom_in`)}
          placement={'left'}
          overlayClassName='tooltip select-date-tooltip'
          arrowPointAtCenter>
          <Button
            data-testid='zoom-in-click'
            className='cw-map-tools__zoom-button cw-map-tools__zoom-button--in'
            icon={<img alt='download' src={ZoomOutIcon} />}
            onClick={zoomInClick}></Button>
        </Tooltip>
        <Tooltip
          zIndex={9999999}
          title={t(`global.map.tooltip.zoom_out`)}
          placement={'left'}
          overlayClassName='tooltip select-date-tooltip'
          arrowPointAtCenter>
          <Button
            data-testid='zoom-out-click'
            className='cw-map-tools__zoom-button cw-map-tools__zoom-button--out'
            icon={<img alt='download' src={ZoomInIcon} />}
            onClick={zoomOutClick}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
