import qs from 'querystring';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { iff } from '../../../utils/iff';
import { isEmptyStr } from '../../../utils/commonMethods';

export const DEFAULT_LOCATION = {
  LAT: -3.1855786,
  LONG: -52.22320109,
  UTC: -4
};
export const DEFAULT_LATLONG = 10.0;
export const MAP_REFRESH_DELAY = 200;

export const apiParams = {
  regulatoryZone: 'regulatory_zone',
  zipCode: 'zip_code',
  timeZone: 'time_zone',
  orgId: 'org_id',
  referencePoint: 'reference_point',
  administrativeAreaLevel1: 'administrative_area_level_1',
  administrativeAreaLevel2: 'administrative_area_level_2',
  administrativeAreaLevel3: 'administrative_area_level_3',
  postalCode: 'postal_code',
  streetNumber: 'street_number',
  sublocalityLevel1: 'sublocality_level_1',
  sublocalityLevel2: 'sublocality_level_2'
};

export const defaultForm = {
  locality: 'long_name',
  [apiParams.administrativeAreaLevel1]: 'short_name',
  [apiParams.administrativeAreaLevel2]: 'short_name',
  [apiParams.administrativeAreaLevel3]: 'short_name',
  country: 'long_name',
  [apiParams.postalCode]: 'short_name',
  political: 'short_name'
};

export const streetAddressForm = {
  [apiParams.streetNumber]: 'short_name',
  premise: 'short_name',
  route: 'short_name',
  political: 'short_name',
  sublocality: 'short_name',
  [apiParams.sublocalityLevel1]: 'short_name',
  [apiParams.sublocalityLevel2]: 'short_name'
};

export const getDefaultUtcOffset = () => {
  const DIVIDE_BY = 60;
  let offset = new Date().getTimezoneOffset();
  if (offset < 0) {
    offset = Math.abs(offset);
  } else {
    offset = -Math.abs(offset);
  }
  offset = offset / DIVIDE_BY;
  return offset;
};

/**
 * Get each component of the address from the place details and fill the corresponding field on the form.
 * @param {*} place - Google place address response
 */
export const transformAddress = (place = {}) => {
  try {
    if (place) {
      const finalAddress = {
        address: ''
      };
      let addressText = '';
      const addressComponents = place?.address_components || [];
      for (const addressComponent of addressComponents) {
        for (const addressType of addressComponent.types) {
          let val;
          if (defaultForm[addressType]) {
            val = addressComponent[defaultForm[addressType]];
            finalAddress[addressType] = val;
          } else if (streetAddressForm[addressType]) {
            val = addressComponent[streetAddressForm[addressType]];
            addressText = `${iff(isEmptyStr(addressText), `${addressText}, `, '')}${val}`;
            finalAddress.address = addressText;
          }
        }
      }
      finalAddress.address = iff(
        isEmpty(place?.formatted_address),
        finalAddress.address,
        place.formatted_address
      );
      getTimezoneFromGoogle(getLatLong(place)).then((res) => {
        finalAddress.timeZone = get(res, 'data.timeZoneId');
      });
      finalAddress.getTZone = getTimezoneFromGoogle(getLatLong(place));
      return finalAddress;
    }
  } catch (ex) {
    console.error('Exception - transformAddress -', ex);
    return null;
  }
};

export const getAddressFromGeocoder = async (latlng) =>
  new Promise((resolve) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      iff(status === 'OK', () => resolve(results.length > 1 ? results[1] : results[0]), resolve);
    });
  });

export const toTimestamp = (strDate) => {
  const datum = Date.parse(strDate);
  return datum / 1000;
};

export const getTimezoneFromGoogle = async (latlng) => {
  const queryParams = {
    key: process.env.REACT_APP_GOOGLE_API_KEY,
    location: `${latlng.lat},${latlng.lng}`,
    timestamp: toTimestamp(new Date())
  };
  const queryString = qs.stringify(queryParams);
  const res = await fetch(
    `${process.env.REACT_APP_GOOGLE_API_HOST}/maps/api/timezone/json?${queryString}`
  );
  const data = await res.json();
  return { data };
};

const floorCordinate = (coordinate) =>
  iff(
    typeof coordinate.toString() === 'string',
    () => Number(coordinate.toString().slice(0, coordinate.toString().indexOf('.') + 9)),
    coordinate
  );

export const getLatLong = (place) => {
  const latLang = {};
  if (get(place, 'geometry.location')) {
    latLang.lat = floorCordinate(place.geometry.location.lat());
    latLang.lng = floorCordinate(place.geometry.location.lng());
  }
  return latLang;
};
