export interface FieldModel {
  id: string;
  parent_region_id: string;
  name: string;
  calculated_area: number;
  declared_area: number;
  geometry: GeoJSON.Polygon;
}

export const FIELD_HAS_ACTIVE_SEASON_ERROR_CODE = 'EFLD020';
export interface ArchiveErrorModel {
  code: typeof FIELD_HAS_ACTIVE_SEASON_ERROR_CODE;
  errors: {
    field_id: string;
    field_name: string;
    season: {
      season_name: string;
    };
  }[];
}
export const FIELD_HAS_OVERLAP_ERROR_CODE = 'EGEO000';
export interface UnarchiveErrorModel {
  code: typeof FIELD_HAS_OVERLAP_ERROR_CODE;
  errors: {
    overlap: {
      field: {
        field_id: string;
        field_name: string;
        field_calculated_area: number;
        field_declared_area: number;
      };
      field_overlapped: {
        field_id: string;
        field_name: string;
        field_calculated_area: number;
        field_declared_area: number;
      };
    }
  }[];
}

export interface RegionModel {
  id: string;
  parent_id: string | null;
  name: string;
  children_region: RegionModel[];
}
export interface RegionNameMapViewModel {
  [regionId: string]: string;
}
