import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getOrgById } from '../../pages/organizations/selectors';

export const getAllFieldsState = (state) => state.allFields || {};

export const isGetAllFieldsLoading = createSelector(getAllFieldsState, (state) => state.loading);

export const isGetAllFieldsError = createSelector(getAllFieldsState, (state) => state.error);

export const getAllFieldsData = createSelector(getAllFieldsState, (state) => state.data);

export const getFieldById = createSelector(getAllFieldsState, (state) => state.fieldById);

export const getPropertyFieldsEntities = createSelector(
  getAllFieldsState,
  (state) => state.propertyFieldEntities
);

export const getPropertiesIDs = createSelector(getAllFieldsState, (state) => state.propertiesIDs);

export const getSeasonFieldsByOrgId = createSelector(
  getOrgById,
  (state, props) => props.seasonId,
  (org, seasonId) => {
    const seasonFields = [];
    if (Object.keys(org).length > 0) {
      const seasons = get(org, 'seasons', []);
      seasons.forEach((season) => {
        if (seasonId && season.id === seasonId) {
          return;
        }
        const seasonProperties = get(season, 'season_properties', []);
        seasonProperties.forEach((sp) => {
          const SeasonFields = get(sp, 'season_fields', []);
          SeasonFields.forEach((sf) => seasonFields.push(sf));
        });
      });
    }
    return seasonFields;
  }
);

export const getAllFieldsDataWithProperty = createSelector(
  getOrgById,
  getAllFieldsData,
  (org, allFields) => {
    let propertiesWithFields = [];
    if (Object.keys(org).length > 0) {
      propertiesWithFields = org.properties.map((property) => {
        return {
          ...property,
          fields: allFields.filter((field) => field.property_id === property.id)
        };
      });
    }
    return propertiesWithFields;
  }
);
