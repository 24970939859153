import React, { ReactNode, useCallback, useContext, useState } from 'react';
import { LimitQuotaModal } from './limit-quota-modal';
import { ILimitQuota, ILimitQuotaModalValue } from './limit-creation.type';

interface LimitCreationContextValue {
  showLimitQuotaModal: (value: ILimitQuota) => void;
}
const LimitCreationContext = React.createContext<LimitCreationContextValue>(
  {} as LimitCreationContextValue
);

export function useLimitCreation() {
  return useContext(LimitCreationContext);
}

export function LimitCreationProvider(props: { children?: ReactNode }) {
  const [isShowLimitQuotaModal, setIsShowLimitQuotaModal] = useState(false);
  const [limitQuotaModalValue, setLimitQuotaModalValue] = useState<ILimitQuotaModalValue>(
    {} as ILimitQuotaModalValue
  );

  const showLimitQuotaModal = useCallback((value: ILimitQuota) => {
    if (!value || !value.limit) return;
    setIsShowLimitQuotaModal(true);

    const totalLimit = Number((value.limit + (value.threshold ? value.threshold / 100 : 0) * value.limit).toFixed(2));
    const availableQuota = value.usage ? Number((totalLimit - value.usage).toFixed(2)) : 0;
    const usedQuota = value.usage ? Number(value.usage.toFixed(2)) : 0;

    setLimitQuotaModalValue({
      total: totalLimit,
      usedValue: usedQuota,
      availableValue: availableQuota
    });
  }, []);

  return (
    <LimitCreationContext.Provider value={{ showLimitQuotaModal }}>
      {props.children}
      <LimitQuotaModal
        isVisible={isShowLimitQuotaModal}
        setIsVisible={setIsShowLimitQuotaModal}
        availableValue={limitQuotaModalValue.availableValue}
        total={limitQuotaModalValue.total}
        usedValue={limitQuotaModalValue.usedValue}
      />
    </LimitCreationContext.Provider>
  );
}
