import { store } from 'redux/reducer/store';
import { createAnalyticsHook } from './analytics';

const withWorkspace = () => {
  const state = store.getState();
  const currentWorkspace = state?.user?.currentworkspaceInfo?.id
    ? state?.user?.currentworkspaceInfo
    : state.connectedDecks.data
        .map(({ workspace_id, name }: any) => ({ id: workspace_id, name }))
        .find(({ id }: any) => id === state?.orgs?.orgById.workspace_id) ??
      {} ??
      {};

  return {
    workspaceId: currentWorkspace?.id ?? undefined,
    workspaceName: currentWorkspace?.name ?? undefined
  };
};

const withOrg = () => {
  const state = store.getState();
  const currentOrg = state?.orgs?.orgById;

  return {
    organizationId: currentOrg?.id || undefined,
    organizationName: currentOrg?.name || undefined
  };
};

const withFarm = () => {
  const state = store.getState();
  const hasProperty =
    window.location.pathname.includes('property') || window.location.pathname.includes('edit-farm');
  const farmId = hasProperty ? window.location.pathname.split('/')?.[5] ?? undefined : undefined;
  let farmName;

  const propertyDetails = farmId
    ? state.propertiesList?.data?.entities?.properties?.[farmId]
    : undefined;
  if (propertyDetails) {
    farmName = propertyDetails.name;
  }

  return {
    farmId,
    farmName
  };
};

export const getGlobalAnalyticsProps = () => ({
  ...withOrg(),
  ...withFarm(),
  ...withWorkspace()
});

export const useAnalyticsWithOrg = createAnalyticsHook(withOrg);
export const useAnalyticsWithFarm = createAnalyticsHook(withFarm);
export const useAnalyticsWithWorkspace = createAnalyticsHook(withWorkspace);
export const useGlobalAnalytics = createAnalyticsHook(
  useAnalyticsWithOrg,
  useAnalyticsWithFarm,
  useAnalyticsWithWorkspace
);
