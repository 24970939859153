import {
  FETCH_SEASONS_BY_CYCLE_ID_ERROR,
  FETCH_SEASONS_BY_CYCLE_ID_LOADING,
  FETCH_SEASONS_BY_CYCLE_ID_SUCCESS,
  FETCH_UNGROUPED_SEASONS_BY_PROPERTY_ERROR,
  FETCH_UNGROUPED_SEASONS_BY_PROPERTY_LOADING,
  FETCH_UNGROUPED_SEASONS_BY_PROPERTY_SUCCESS,
  FETCH_CYCLE_BY_PROP_ID_ERROR,
  FETCH_CYCLE_BY_PROP_ID_LOADING,
  FETCH_CYCLE_BY_PROP_ID_SUCCESS,
  RESET_SEASONS_BY_CYCLE_ID,
  DELETE_CYCLE_BY_ID_ERROR,
  DELETE_CYCLE_BY_ID_LOADING,
  DELETE_CYCLE_BY_ID_SUCCESS,
  CREATE_CYCLE_ERROR,
  CREATE_CYCLE_LOADING,
  CREATE_CYCLE_SUCCESS,
  ATTACH_DETACH_SEASONS_TO_CYCLE_LOADING,
  ATTACH_DETACH_SEASONS_TO_CYCLE_ERROR,
  ATTACH_DETACH_SEASONS_TO_CYCLE_SUCCESS,
  UPDATE_CYCLE_LOADING,
  UPDATE_CYCLE_ERROR,
  UPDATE_CYCLE_SUCCESS,
} from '../actions/types';
  
const initialState = {
  loading: false,
  error: false,
  seasonsByCycle: [],
  cyclesByPropIdLoading: false,
  cyclesByPropId: [],
  ungroupedSeasonsByProperty: [],
  cycle: {},
  seasonAcceptance: {}
};

export const cycles = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEASONS_BY_CYCLE_ID_LOADING:
    case FETCH_UNGROUPED_SEASONS_BY_PROPERTY_LOADING:
    case DELETE_CYCLE_BY_ID_LOADING:
    case CREATE_CYCLE_LOADING:
    case ATTACH_DETACH_SEASONS_TO_CYCLE_LOADING:
    case UPDATE_CYCLE_LOADING:
      return { ...state, loading: true };
    case FETCH_CYCLE_BY_PROP_ID_LOADING:
      return { ...state, cyclesByPropIdLoading: true }
    case FETCH_SEASONS_BY_CYCLE_ID_SUCCESS:
      return { ...state, seasonsByCycle: state.seasonsByCycle.concat(action.payload), error: false, loading: false };
    case FETCH_UNGROUPED_SEASONS_BY_PROPERTY_SUCCESS:
      return { ...state, ungroupedSeasonsByProperty: action.payload, error: false, loading: false};
    case FETCH_CYCLE_BY_PROP_ID_SUCCESS:
      return { ...state, cyclesByPropId: action.payload, error: false, cyclesByPropIdLoading: false};
    case DELETE_CYCLE_BY_ID_SUCCESS:
      return { ...state, error: false, loading: false};
    case CREATE_CYCLE_SUCCESS:
    case UPDATE_CYCLE_SUCCESS:
      return { ...state, cycle: action.payload, error: false, loading: false};
    case ATTACH_DETACH_SEASONS_TO_CYCLE_SUCCESS:
      return { ...state, seasonAcceptance: action.payload, error: false, loading: false};
    case FETCH_UNGROUPED_SEASONS_BY_PROPERTY_ERROR:
    case FETCH_CYCLE_BY_PROP_ID_ERROR:
    case FETCH_SEASONS_BY_CYCLE_ID_ERROR:
    case DELETE_CYCLE_BY_ID_ERROR:
    case CREATE_CYCLE_ERROR:
    case ATTACH_DETACH_SEASONS_TO_CYCLE_ERROR:
    case UPDATE_CYCLE_ERROR:
      return { ...state, error: true, loading: false };
    case RESET_SEASONS_BY_CYCLE_ID:
      return { ...state, seasonsByCycle: [], error: false, loading: false };
    default:
      return state;
  }
};
