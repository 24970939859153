import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DateContainer = styled.div`
  margin-left: 12px;

  .archived-label {
    color: #868ca2;
    margin-left: 4px;
  }
`;
