import styled, { css } from "styled-components";


export const Button = styled.button<{ $dark: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 16px;
  height: 100%;
  border: 1px solid;
  border-radius: 4px;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  border-color: #ffffff;
  color: #ffffff;

  svg * {
    fill: #ffffff;
  }

  &:hover {
    border-color: #9699a5;
  }

  ${({ $dark, $disabled }) =>
    $dark &&
    !$disabled &&
    css`
      border-color: #c2c7d0;
      color: #14151c;

      svg * {
        fill: #696f88;
      }

      &:hover {
        border-color: #14151c;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      border-color: #9699a5;
      color: #4d5165;
      opacity: 0.6;

      svg * {
        fill: #696f88;
      }
    `}
`;