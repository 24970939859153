import locale_en from 'antd/lib/locale/en_US';
import locale_pt from 'antd/lib/locale/pt_BR';
import locale_es from 'antd/lib/locale/es_ES';
import locale_fr from 'antd/lib/locale/fr_FR';
import locale_german from 'antd/lib/locale/de_DE';
import locale_polski from 'antd/lib/locale/pl_PL';
import locale_bulgarian from 'antd/lib/locale/bg_BG';
import locale_hungarian from 'antd/lib/locale/hu_HU';
import locale_romanian from 'antd/lib/locale/ro_RO';
import locale_greek from 'antd/lib/locale/el_GR';
import locale_dutch from 'antd/lib/locale/nl_NL';
import locale_danish from 'antd/lib/locale/da_DK';
import locale_swedish from 'antd/lib/locale/sv_SE';
import locale_ukrainian from 'antd/lib/locale/uk_UA';
import locale_italian from 'antd/lib/locale/it_IT';
import locale_slovak from 'antd/lib/locale/sk_SK';
import locale_russian from 'antd/lib/locale/ru_RU';
import locale_czech from 'antd/lib/locale/cs_CZ';
import locale_japanese from 'antd/lib/locale/ja_JP';


import 'moment/locale/pt-br';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/pl';
import 'moment/locale/es-us';
import 'moment/locale/bg';
import 'moment/locale/hu';
import 'moment/locale/ro';
import 'moment/locale/el';
import 'moment/locale/nl';
import 'moment/locale/da';
import 'moment/locale/sv';
import 'moment/locale/uk';
import 'moment/locale/it';
import 'moment/locale/sk';
import 'moment/locale/ru';
import 'moment/locale/cs';
import 'moment/locale/ja';

import { getLocaleUsingCookies } from './commonMethods';

export const getLocale = () => {
  const selectedLanguage = localStorage.getItem('i18nextLng') || getLocaleUsingCookies() || 'en';
  let locale = locale_en;
  switch (selectedLanguage) {
    case 'pt-BR':
      locale = locale_pt;
      break;
    case 'es':
      locale = locale_es;
      break;
    case 'fr':
      locale = locale_fr;
    break;
    case 'de':
      locale = locale_german;
    break;
    case 'pl':
      locale = locale_polski;
    break;
    case 'bg':
      locale = locale_bulgarian;
    break;
    case 'hu':
      locale = locale_hungarian;
    break;
    case 'ro':
      locale = locale_romanian;
    break;
    case 'el':
      locale = locale_greek;
    break;
    case 'nl':
      locale = locale_dutch;
    break;
    case 'da':
      locale = locale_danish;
    break;
    case 'sv':
      locale = locale_swedish;
    break;
    case 'uk':
      locale = locale_ukrainian;
    break;
    case 'it':
      locale = locale_italian;
    break;
    case 'sk':
      locale = locale_slovak;
    break;
    case 'ru':
      locale = locale_russian;
    break;
    case 'cs':
      locale = locale_czech;
    break;
    case 'ja':
      locale = locale_japanese;
    break;
    
    default:
      locale = locale_en;
  }
  return locale;
};
