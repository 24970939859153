import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: 12px;
  background-color: #363948;

  position: absolute;
  transform: translateX(-50%);
  left: 50%;

  &.floating-bulk-actions-exit,
  &.floating-bulk-actions-enter-done {
    bottom: 24px;
  }

  &.floating-bulk-actions-exit-active {
    bottom: -108px;
    transition: bottom 300ms ease-in;
  }

  &.floating-bulk-actions-enter {
    bottom: -108px;
  }

  &.floating-bulk-actions-enter-active {
    transition: bottom 300ms ease-out;
    bottom: 24px;
  }
`;

export const InnerContainer = styled.div`
  border-radius: 12px;
  background-color: #232630;
  padding: 12px;

  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;

  .title,
  .close {
    color: #a3a9b9;
  }

  .title {
    margin-right: 24px;
  }

  .close {
    cursor: pointer;
    margin-left: 4px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const Button = styled.button<{ $danger?: boolean; $disabled?: boolean }>`
  cursor: pointer;
  background: none;
  outline: none;
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 32px;
  transition: opacity 300ms ease-in-out;
  color: #ffffff;

  svg,
  path {
    fill: #fff;
    color: #fff;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  & + & {
    margin-left: 12px;
  }

  ${({ $danger }) =>
    $danger &&
    css`
      margin-left: 32px !important;
      color: #eb4b4b;
      border-color: #eb4b4b;

      svg,
      path {
        fill: #eb4b4b;
        color: #eb4b4b;
      }
    `}

  ${({ $disabled }) =>
    $disabled
      ? css`
          cursor: not-allowed;
          color: #4d5165;
          border-color: #4d5165;

          svg,
          path {
            color: #4d5165;
            fill: #4d5165;
          }
        `
      : css`
          :hover {
            opacity: 0.6;
          }
        `}
`;
