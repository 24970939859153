import { createSelector } from 'reselect';
import { getAreaByUnit, getUnitText } from '../../utils/getAreaByUnit';

export const getOrgs = (state) => state.orgs || {};

export const getOrgsData = (state, { id } = {}) => {
  const data = getOrgs(state).data || [];
  if (data.length && id) {
    return data.filter((d) => d.id === id);
  }
  return getOrgs(state).data || [];
};

export const getNoOfOrgs = createSelector(
  getOrgs,
  (orgs) => (orgs.data && Array.isArray(orgs.data) && orgs.data.length) || 0
);

export const getOrgById = createSelector(getOrgsData, (data) => data[0] || {});

export const getSelectedOrg = (state) => {
  return getOrgs(state).selectedOrg;
};

export const getSelectedProperty = (state) => {
  return getOrgs(state).selectedProperty;
};

export const getSelectedSeasons = (state) => {
  return getOrgs(state).selectedSeasons;
};

export const getSelectedPropFields = (state) => {
  return getOrgs(state).selectedPropFields;
};

export const isLoading = (state) => {
  return getOrgs(state).loading;
};

export const getError = (state) => {
  return getOrgs(state).error;
};

/**
 * To generate address string in this format
 * "address, city, zip - state, country"
 */
const formatAddressString = ({ address, city, zip_code, state, country }) => {
  let finalStr = '';
  let arr = [];

  if (address) {
    arr.push(address);
  }
  if (city) {
    arr.push(city);
  }
  finalStr += ` ${arr.join(', ')}`;

  if (zip_code) {
    finalStr += `, ${zip_code} - `;
  } else if (!finalStr) {
    finalStr += ', ';
  }
  arr = [];

  if (state) {
    arr.push(state);
  }
  if (country) {
    arr.push(country);
  }
  finalStr += ` ${arr.join(', ')}`;

  return finalStr;
};

export const getOrgsDetails = (state) => {
  const orgs = getOrgsData(state);

  return orgs.map((org) => ({
    name: org.name,
    id: org.id,
    address: formatAddressString(org),
    totalArea: {
      area: getAreaByUnit(org.total_area, org.unit_system),
      unit: getUnitText(org.unit_system)
    },
    noOfProperties: (org.properties && Array.isArray(org.properties) && org.properties.length) || 0,
    crops:
      org.seasons &&
      Array.isArray(org.seasons) &&
      org.seasons.map((s) => ({
        id: s.crop.id,
        name: s.crop.name,
        image: s.crop.image_path,
        wk_slug: s.crop.wk_slug
      }))
  }));
};

export const getPropertyById = (id) => {
  const orgs = getOrgs();
  let property = {};
  orgs.forEach((org) => {
    property = org.properties.find((prop) => prop.id === id) || {};
  });
  return property;
};
