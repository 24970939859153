import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { normalize } from 'normalizr';
import { v1 as uuidv1 } from 'uuid';

import { AppConstants } from 'utils/app.constants';
import { request } from '../../utils/axios';
import { getHeaders, getRandomValue, sleep, validateUUID } from '../../utils/commonMethods';
import { iff } from '../../utils/iff';
import * as schema from './schema';
import {
  FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS,
  FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING,
  FETCH_ALL_FIELDS_BY_ORG_LOADING,
  FETCH_ALL_FIELDS_BY_ORG_ERROR,
  FETCH_ALL_FIELDS_BY_ORG_SUCCESS,
  UPDATE_EXISTING_PROPERTY_IDS,
  RESET_ALL_FIELDS,
  FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS,
  FETCH_ALL_FIELDS_BY_PROP_ID_ERROR,
  FETCH_ALL_FIELDS_BY_PROP_ID_LOADING,
  FETCH_FIELDS_BY_ID_LOADING,
  FETCH_FIELDS_BY_ID_SUCCESS,
  FETCH_FIELDS_BY_ID_ERROR,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_RESET
} from './types';
import { history, URL_CONSTANTS } from '../../utils/history';
import { getFieldsBySeasonsAndPropId } from '../../redux/selectors/organizationSelectors';
import { getPropertiesIDs, getPropertyFieldsEntities } from '../../redux/selectors/fieldsSelectors';
import { manageRedirection } from './../../pages/multi-region/commonFunctions';
import moment from 'moment';
import { DATE_FORMATS } from 'utils/dateUtils';

const COLORS = ['red', 'green', 'yellow', 'blue', 'orange', 'black'];
const dataError = 'data.errors';

export function fetchFieldsBySeasonsAndPropIdLoading() {
  return {
    type: FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING
  };
}

export function fetchFieldsBySeasonsAndPropIdSuccess(fields) {
  return {
    type: FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS,
    payload: fields
  };
}

export const getHeadersWithClientStrixUi = () => {
  const _headers =  getHeaders()
  _headers.common['client-id'] = 'strix-ui'
  return _headers
}

export const fetchFieldsBySeasonsAndPropId = ({ orgId, seasons, propId }) => {
  return (dispatch, getState) => {
    dispatch(fetchFieldsBySeasonsAndPropIdLoading());
    const allFields = [];
    const getSeasonDetails = (season) => {
      const i = getRandomValue(COLORS.length);
      const color = COLORS[i];
      let fieldsBySeason = getFieldsBySeasonsAndPropId(getState(), {
        orgId,
        seasonId: season,
        propId
      });
      fieldsBySeason = fieldsBySeason.map((field) => ({ ...field, color }));
      allFields.push(fieldsBySeason);
    };
    for (const season of seasons) {
      getSeasonDetails(season);
    }
    dispatch(fetchFieldsBySeasonsAndPropIdSuccess(allFields));
  };
};

export function fetchAllFieldsByPropIdLoading() {
  return {
    type: FETCH_ALL_FIELDS_BY_PROP_ID_LOADING
  };
}

export function fetchAllFieldsByPropIdError(err) {
  return {
    type: FETCH_ALL_FIELDS_BY_PROP_ID_ERROR,
    err
  };
}

export function fetchAllFieldsByPropIdSuccess(fields) {
  return {
    type: FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS,
    payload: fields,
    normalizedPayload: normalize(fields, schema.FieldsSchema)
  };
}

let fieldsByPropertyIdCache = {};
let allFieldsHistoryByPropIdCache = {};
let fieldByIdCache = {};
export function clearFieldsCache() {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
}

export const fetchAllFieldsByPropId = (propId, fieldVersioningDate) => {
  const getFieldVersioningString = (fieldDate) => {
    const currentDate = moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);
    return fieldDate ? `&reference_date=${fieldDate}` : `&reference_date=${currentDate}`;
  };
  return (dispatch) => {
    if (fieldsByPropertyIdCache[`${propId}-${fieldVersioningDate}`]?.data) {
      dispatch(
        fetchAllFieldsByPropIdSuccess(
          fieldsByPropertyIdCache[`${propId}-${fieldVersioningDate}`].data.content
        )
      );
      return fieldsByPropertyIdCache[`${propId}-${fieldVersioningDate}`];
    }
    dispatch(fetchAllFieldsByPropIdLoading());

    request({
      method: 'get',
      url: `/v2/properties/${propId}/fields?include_extended=true&attributes=geometry${getFieldVersioningString(
        fieldVersioningDate
      )}`,
      headers: getHeadersWithClientStrixUi()
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(fetchAllFieldsByPropIdSuccess(res.data.content));
        fieldsByPropertyIdCache[`${propId}-${fieldVersioningDate}`] = res;
        return res;
      })
      .catch((error) => {
        fieldsByPropertyIdCache[`${propId}-${fieldVersioningDate}`] = undefined;
        dispatch(fetchAllFieldsByPropIdError(error));
        return Promise.reject(error);
      });
    return fieldsByPropertyIdCache[`${propId}-${fieldVersioningDate}`];
  };
};

export const getFieldsByPropertyId = async (propId, referenceDate = null) => {
  if (
    fieldsByPropertyIdCache[`${propId}-${referenceDate}`] &&
    fieldsByPropertyIdCache[`${propId}-${referenceDate}`].data
  ) {
    return fieldsByPropertyIdCache[`${propId}-${referenceDate}`];
  }
  const referenceDateQueryParam = '&reference_date=';
  let currentDate = moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);
  const referenceDateQueryParamValue = iff(
    isEmpty(referenceDate) === false,
    referenceDateQueryParam + referenceDate,
    referenceDateQueryParam + currentDate
  );

  const queryParams = `include_extended=true&attributes=geometry${referenceDateQueryParamValue}`;

  try {
    fieldsByPropertyIdCache[`${propId}-${referenceDate}`] = await request.get(
      `/v2/properties/${propId}/fields?${queryParams}`,
      {
        headers: getHeaders()
      }
    );
    return fieldsByPropertyIdCache[`${propId}-${referenceDate}`];
  } catch (e) {
    fieldsByPropertyIdCache[`${propId}-${referenceDate}`] = undefined;
    // eslint-disable-next-line
    return {
      data: { content: [] },
      error: e
    };
  }
};

export const createFieldByPropId = async ({
  property,
  name,
  geometry,
  declared_area,
  orgid,
  shouldRedirect = true,
  parent_id = null
}) => {
  const data = {
    name,
    property_id: property.id,
    parent_region_id: parent_id || property.root_region_id,
    geometry: cleanupGeometry(geometry),
    declared_area
  };
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};

  const propertyRes = await request.post(`/v2/fields`, data, {
    headers: getHeaders()
  });
  if (!isEmpty(propertyRes) && !isEmpty(propertyRes.data.errors)) {
    throw propertyRes.data.errors;
  }
  if (!isEmpty(propertyRes) && propertyRes.status === 201 && shouldRedirect) {
    history.push(URL_CONSTANTS.ALL_FIELDS({ orgId: orgid, farmId: property.id }));
  }
};

const cleanupGeometry = (g) => {
  if (!g) {
    return null;
  }

  const geometry = JSON.parse(JSON.stringify(g));
  if (
    geometry &&
    geometry.type === 'MultiPolygon' &&
    geometry.coordinates &&
    geometry.coordinates.length <= 1
  ) {
    geometry.type = 'Polygon';
    // If Multipolygon has multiple holes/sections so assign all to coordinates.
    if (geometry.coordinates[0].length > 1) {
      geometry.coordinates = geometry.coordinates[0];
    } else {
      geometry.coordinates[0] = geometry.coordinates[0][0];
    }
  }
  return geometry;
};

export const createFieldByPropIdPromisify = async ({
  property,
  name,
  geometry,
  declared_area,
  parent_id,
  calculated_area,
  eventDate
}) => {
  const data = {
    name,
    property_id: property.id,
    parent_region_id: parent_id || property.root_region_id,
    geometry: cleanupGeometry(geometry),
    declared_area,
    calculated_area,
    event_date: eventDate
  };
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};

  return request.post(`${process.env.REACT_APP_API_URL}/v2/fields`, data, {
    headers: getHeaders()
  });
};

const getValidUUID = (id) => (validateUUID(id) ? id : uuidv1());

const getUpdatedFields = (
  newFields,
  parentId,
  operation,
  eventDate,
  isCreateFieldUsingDetectField
) => {
  return newFields.map((field) => {
    const {
      id,
      name,
      geometry,
      declared_area,
      calculated_area,
      buffer_zone,
      max_cut_out,
      event_date,
      json_extended_attributes
    } = field;
    const fieldId = isCreateFieldUsingDetectField ? uuidv1() : getValidUUID(id); // Create new id while creating field in detect field, It should be unique in data base
    const updatedField = {
      type: 'Feature',
      id: fieldId,
      geometry:
        geometry && geometry !== null && Object.values(geometry).length > 0 ? geometry : null,
      event_date: field.event_date,
      properties: {
        name,
        parent_id: parentId,
        operation: operation,
        id: fieldId,
        declared_area,
        calculated_area,
        ...((operation !== 'delete' && !isEmpty(eventDate) && { event_date: eventDate }) ||
          (operation !== 'delete' && !isEmpty(event_date) && { event_date: event_date }) ||
          {})
      },
      json_extended_attributes,
      backup_detected_field_id: id
    };
    if (buffer_zone) {
      updatedField.properties.buffer_zone = buffer_zone;
    }
    if (max_cut_out) {
      updatedField.properties.max_cut_out = max_cut_out;
    }
    return updatedField;
  });
};

const addJsonExtendedAttributesOfFields = async (fields, farmId) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  const sigpacFields = fields.map((field) => {
    const properties = field.properties || {};
    const isCreateFieldUsingDetectField = !!field.json_extended_attributes;
    const jsonExtendedAttributes = field.json_extended_attributes || {};
    jsonExtendedAttributes.detected_field_id = field.backup_detected_field_id;
    return {
      json_extended_attributes: jsonExtendedAttributes,
      property_id: farmId,
      parent_region_id: properties.parent_id,
      declared_area: properties.declared_area,
      name: properties.name,
      id: properties.id,
      geometry: field.geometry,
      isCreateFieldUsingDetectField
    };
  });

  const response = await Promise.all(
    sigpacFields.map((field) =>
      // eslint-disable-next-line no-use-before-define
      editFieldById({
        property: { id: field.property_id },
        parent_id: field.parent_region_id,
        name: field.name,
        declared_area: field.declared_area,
        json_extended_attributes: field.json_extended_attributes,
        fieldId: field.id,
        geometry: field.geometry,
        isCreateFieldUsingDetectField: field.isCreateFieldUsingDetectField
      })
    )
  );
  return response;
};

export const createBulkFieldsByPropId = async ({
  parentId,
  newFields,
  orgId,
  farmId,
  operation = 'create',
  fromLandingPropertyTableView = false,
  fromRegion = false,
  fromDeepAction = false,
  eventDate,
  fromManageRegions = false,
  shouldRedirectAfterSuccess = true,
  navigate,
  isCreateFieldUsingDetectField = false,
  fieldValueFromState = [],
  allFieldsDeleteClicked = false
}) => {
  try {
    fieldsByPropertyIdCache = {};
    allFieldsHistoryByPropIdCache = {};
    fieldByIdCache = {};

    const data = {
      version: 'v1',
      strict: true,
      relative_root: parentId,
      format: 'geojson',
      region_set: {
        type: 'FeatureCollection',
        features: getUpdatedFields(
          newFields,
          parentId,
          operation,
          eventDate,
          isCreateFieldUsingDetectField
        )
      }
    };

    const propertyRes = await request.post(`/v2/properties/${farmId}/fields/updates`, data, {
      headers: getHeaders()
    });
    if (isCreateFieldUsingDetectField) {
      const fieldsToUpdate = get(data, 'region_set.features');
      await addJsonExtendedAttributesOfFields(fieldsToUpdate, farmId);
    }
    if (!isEmpty(propertyRes) && propertyRes.status === 201) {
      if (propertyRes.data.status === 'REJECTED') {
        throw propertyRes.data.errors;
      }
      if (
        !fromLandingPropertyTableView &&
        !fromRegion &&
        !fromDeepAction &&
        !fromManageRegions &&
        operation !== 'delete' &&
        shouldRedirectAfterSuccess
      ) {
        if (navigate) {
          navigate(URL_CONSTANTS.ALL_FIELDS({ orgId, farmId }), {
            state: {
              newFields: newFields
            }
          });
        }
      }
      if (fromRegion && newFields.length > 0) {
        manageRedirection({
          orgId,
          farmId,
          parentId,
          newFields,
          isFildsAdded: true,
          navigate: navigate
        });
      }
      afterFieldDeleteFunc(
        operation,
        allFieldsDeleteClicked,
        fieldValueFromState,
        navigate,
        newFields,
        orgId,
        farmId
      );
    }
    sleep(1000);
    return propertyRes;
  } catch (ex) {
    const response = ex.response;
    if (fromDeepAction) {
      throw response.data;
    } else if (isEmpty(get(response, dataError))) {
      throw ex;
    }
    throw get(response, dataError);
  }
};

function afterFieldDeleteFunc(
  operation,
  allFieldsDeleteClicked,
  fieldValueFromState,
  navigate,
  newFields,
  orgId,
  farmId
) {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  if (operation === 'delete' && allFieldsDeleteClicked && fieldValueFromState.length > 0) {
    const newFieldRes = fieldValueFromState?.filter((stateField) => {
      const result = newFields?.find((selectedField) => selectedField.id === stateField.id);
      return !result;
    });
    navigate(URL_CONSTANTS.ALL_FIELDS({ orgId, farmId }), {
      state: {
        newFields: newFieldRes
      },
      replace: true
    });
  }
}

export const editFieldById = async ({
  property,
  name,
  geometry,
  declared_area,
  fieldId,
  parent_id,
  calculated_area,
  event_date,
  json_extended_attributes,
  isCreateFieldUsingDetectField = false
}) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  const data = {
    name,
    property_id: property.id,
    parent_region_id: parent_id || property.root_region_id,
    geometry: cleanupGeometry(geometry),
    declared_area,
    id: fieldId,
    calculated_area,
    event_date
  };
  let includeExtended = false;
  if (json_extended_attributes) {
    includeExtended = true;
    data.json_extended_attributes = json_extended_attributes;
  }

  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};

  const headers = getHeaders();
  if (isCreateFieldUsingDetectField) {
    headers.common['client-id'] = AppConstants.SIGPAC_FIELD_CLIENT_ID;
  }
  const currentDate = moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);
  return request.put(
    `/v2/fields/${fieldId}?reference_date=${currentDate}${
      includeExtended ? '&include_extended=true' : ''
    }`,
    data,
    {
      headers
    }
  );
};

export const getFieldById = async (id) => {
  if (fieldByIdCache[id]) return fieldByIdCache[id];
  const currentDate = moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);

  fieldByIdCache[id] = await request.get(
    `/v2/fields/${id}?include_extended=true&attributes=geometry&reference_date=${currentDate}`,
    {
      headers: getHeadersWithClientStrixUi()
    }
  );
  return fieldByIdCache[id];
};

export const fetchFieldById = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_FIELDS_BY_ID_LOADING });
    try {
      const res = await getFieldById(id);
      if (res.error) {
        throw res.error;
      }
      dispatch({ type: FETCH_FIELDS_BY_ID_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: FETCH_FIELDS_BY_ID_ERROR, error: error });
    }
  };
};

export const deleteFieldByFieldId = async (fieldId) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  const response = await request.delete(`/v2/fields/${fieldId}`, {
    headers: getHeaders()
  });
  return response;
};

export const deleteFieldVersionByFieldIdAndVersionId = (fieldId, versionId) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  return request.delete(`/v2/fields/${fieldId}/history/${versionId}`, {
    headers: getHeaders()
  });
};

export const deleteFieldVersionByFieldIdAndEventDate = (fieldId, date) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  return request.delete(`/v2/fields/${fieldId}/event-date/${date}`, {
    headers: getHeaders()
  });
};

/**
 * to delete version of a field using version id
 * @param {*} fieldId
 * @param {*} versionId
 * @returns
 */
export const deleteFieldVersionById = (fieldId, versionId) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  return request.delete(`v2/fields/${fieldId}/history/${versionId}`, {
    headers: getHeaders()
  });
};

export function fetchAllFieldsByOrgLoading() {
  return {
    type: FETCH_ALL_FIELDS_BY_ORG_LOADING
  };
}

export function fetchAllFieldsByOrgSuccess(fields) {
  return {
    type: FETCH_ALL_FIELDS_BY_ORG_SUCCESS,
    payload: fields
  };
}

export function updateExistingPropertyIds(properties) {
  return {
    type: UPDATE_EXISTING_PROPERTY_IDS,
    payload: properties
  };
}

export function fetchAllFieldsByOrgError(err) {
  return {
    type: FETCH_ALL_FIELDS_BY_ORG_ERROR,
    err
  };
}
export const updatePropertyFieldsEntities = (payload) => {
  return {
    type: 'UPDATE_PROPERTIES_FIELDS_ENTITIES',
    payload: payload
  };
};

/**
 * to fetch fields of multiple properties using ids
 * @param {*} propIds
 */
export const getFieldsOfMultipleProperties = async (propIds, setIsLoadingFields) => {
  const fieldsByProperty = {};
  if (setIsLoadingFields) setIsLoadingFields(true);

  const chunkSize = 100;
  const promises = [];
  const currentDate = moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);
  for (let i = 0; i < propIds.length; i += chunkSize) {
    const chunk = propIds.slice(i, i + chunkSize);
    promises.push(
      (async () => {
        try {
          return await request.post(
            `/v2/properties/ids/fields?reference_date=${currentDate}`,
            { ids: chunk },
            { headers: getHeaders() }
          );
        } catch {}
      })()
    );
  }
  await Promise.all(promises).then((responses) => {
    responses.forEach((response) => {
      response?.data.content.forEach((field) => {
        const currentFields = fieldsByProperty[field.property_id] ?? [];
        fieldsByProperty[field.property_id] = [...currentFields, field];
      });
    });
  });

  if (setIsLoadingFields) setIsLoadingFields(false);
  return fieldsByProperty;
};

export const getAllFieldsOfOrg = (propertiesIDs) => {
  return async (dispatch, getState) => {
    const state = getState();
    const existingFields = getPropertyFieldsEntities(state);
    const existingPropertiesIDs = getPropertiesIDs(state);
    const newPropertiesIDs = propertiesIDs.filter((pid) => existingPropertiesIDs.indexOf(pid) < 0);
    if (newPropertiesIDs.length < 1) {
      return;
    }
    dispatch(updateExistingPropertyIds([...newPropertiesIDs, ...existingPropertiesIDs]));
    dispatch(fetchAllFieldsByOrgLoading());
    try {
      const mappedFieldAndProps = await getFieldsOfMultipleProperties(newPropertiesIDs);
      dispatch(updatePropertyFieldsEntities({ ...mappedFieldAndProps, ...existingFields }));
    } catch (error) {
      dispatch(fetchAllFieldsByOrgError(error));
    }
  };
};

export const resetAllFields = () => {
  return {
    type: RESET_ALL_FIELDS
  };
};

export const createBulkFieldsByPropIdForDetectedFields = async ({
  parentId,
  newFields,
  farmId
}) => {
  try {
    fieldsByPropertyIdCache = {};
    allFieldsHistoryByPropIdCache = {};
    fieldByIdCache = {};
    const data = {
      version: 'v1',
      strict: false,
      relative_root: parentId,
      format: 'geojson',
      region_set: {
        type: 'FeatureCollection',
        features: newFields.map(({ id, name, geometry, adjustedSize }) => {
          return {
            type: 'Feature',
            id,
            geometry:
              geometry && geometry !== null && Object.values(geometry).length > 0 ? geometry : null,
            properties: {
              name,
              parent_id: parentId,
              operation: 'create',
              id,
              declared_area: adjustedSize
            }
          };
        })
      }
    };

    const propertyRes = await request.post(
      `${process.env.REACT_APP_API_URL}/v2/properties/${farmId}/fields/updates`,
      data,
      {
        headers: getHeaders()
      }
    );
    if (!isEmpty(propertyRes) && propertyRes.status === 201) {
      if (propertyRes.data.status === 'REJECTED') {
        throw propertyRes.data.errors;
      }
    }
    return propertyRes;
  } catch (ex) {
    const response = ex.response;
    if (isEmpty(get(response, dataError))) {
      throw ex;
    }
    throw get(response, dataError);
  }
};

export function fetchAllFieldsHistoryByPropIdLoading() {
  return {
    type: FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING
  };
}

export function fetchAllFieldsHistoryByPropIdError(err) {
  return {
    type: FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR,
    err
  };
}

export function fetchAllFieldsHistoryByPropIdSuccess(fields) {
  return {
    type: FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS,
    payload: fields
  };
}

export const fetchAllFieldsHistoryByPropId = (propId) => {
  return (dispatch) => {
    if (allFieldsHistoryByPropIdCache[propId]) {
      dispatch(
        fetchAllFieldsHistoryByPropIdSuccess(allFieldsHistoryByPropIdCache[propId].data.fields)
      );
      return allFieldsHistoryByPropIdCache[propId];
    }
    dispatch(fetchAllFieldsHistoryByPropIdLoading());
    request({
      method: 'get',
      url: `/v2/properties/${propId}/fields/history?attributes=geometry`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(fetchAllFieldsHistoryByPropIdSuccess(res.data.fields));
        allFieldsHistoryByPropIdCache[propId] = res;
        return res;
      })
      .catch((error) => {
        allFieldsHistoryByPropIdCache[propId] = undefined;
        dispatch(fetchAllFieldsHistoryByPropIdError(error));
        return Promise.reject(error);
      });
    return allFieldsHistoryByPropIdCache[propId];
  };
};

export function fetchFieldHistoryDetailsByIdLoading() {
  return {
    type: FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING
  };
}

export function fetchFieldHistoryDetailsByIdError(err) {
  return {
    type: FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR,
    err
  };
}

export function fetchFieldHistoryDetailsByIdSuccess(fieldHistory) {
  return {
    type: FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS,
    payload: fieldHistory
  };
}

/**
 * get field history details by field id
 * @param {*} propId
 * @returns
 */
export const fetchFieldHistoryDetailsById = (fieldId) => {
  return (dispatch) => {
    dispatch(fetchFieldHistoryDetailsByIdLoading());
    return request({
      method: 'get',
      url: `/v2/fields/${fieldId}/history`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(fetchFieldHistoryDetailsByIdSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(fetchFieldHistoryDetailsByIdError(error?.response?.data || error));
        return Promise.reject(error);
      });
  };
};

/**
 * get field history details by field id
 * @param {*} propId
 * @returns
 */
export const resetFieldHistoryDetailsById = () => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  return (dispatch) => {
    dispatch({
      type: FETCH_FIELD_HISTORY_DETAILS_BY_ID_RESET
    });
    return true;
  };
};
