import { getSecondsBeforeQueueAsyncService, isAccountUsingAsyncService } from 'redux/selectors/rolloutSelector';
import { notifications } from './notifications';
import { store } from 'redux/reducer/store';
import React from 'react';
import { useSelector } from 'react-redux';

type Resolve<T> = (value: T | PromiseLike<T>) => void;
type Reject = (reason?: unknown) => void;

interface Request<T> {
  label: string;
  dispatch: () => Promise<T>;
  successAction?: (value: T) => void;
}
interface RequestWithResolveAndReject<T> extends Request<T> {
  resolve: Resolve<T>;
  reject: Reject;
}
interface Queues {
  [key: string]: {
    label: string;
    firstRequestTimedOut: boolean;
    queue: RequestWithResolveAndReject<any>[];
  };
}
type QueueResponse<T> =
  | {
      enqueued: true;
      $listener: Promise<T>;
    }
  | {
      enqueued: false;
      value: T;
    };

const MAX_SECONDS_BEFORE_QUEUE = 10;

class AsyncRequestsServiceClass {
  private queues: Queues = {};
  private listeners: (() => void)[] = [];

  public createQueue(key: string, label: string) {
    if (!this.queues[key]) {
      this.queues[key] = {
        firstRequestTimedOut: false,
        label,
        queue: []
      };
      this.emitChange()
    }
  }

  public queueRequest<T>(parentKey: string, request: Request<T>): Promise<QueueResponse<T>> {
    if (this.queues[parentKey]) {
      const [length, $listener] = this.pushRequest(parentKey, request);

      // If this is the first request on the queue, we trigger it
      if (length === 1) {
        this.queues[parentKey].firstRequestTimedOut = false;
        this.emitChange();
        
        return this.runFirstRequestAndWaitMaxSeconds(parentKey);
      } else {
        notifications.concurrency();
        return Promise.resolve({
          enqueued: true,
          $listener
        });
      }
    }
    return Promise.reject(`Queue with key ${parentKey} does not exist!`);
  }

  public cancelRequest(parentKey: string, index: number) {
    if (this.queues[parentKey]) {
      this.queues[parentKey].queue.splice(index, 1);

      this.emitChange();
    } else {
      throw new Error(`Queue with key ${parentKey} does not exist!`);
    }
  }

  private pushRequest<T>(parentKey: string, request: Request<T>) {
    let requestResolve: Resolve<T> | undefined, requestReject: Reject | undefined;

    const $listener = new Promise<T>((resolve, reject) => {
      requestResolve = resolve;
      requestReject = reject;
    });

    const length = this.queues[parentKey].queue.push({
      ...request,
      resolve: requestResolve as Resolve<T>,
      reject: requestReject as Reject
    });

    this.emitChange();

    return [length, $listener] as const;
  }

  private runFirstRequestAndWaitMaxSeconds<T>(parentKey: string) {
    const maxSecondsBeforeQueue =
      getSecondsBeforeQueueAsyncService(store.getState()) || MAX_SECONDS_BEFORE_QUEUE;

    return new Promise<QueueResponse<T>>((resolve, reject) => {
      let done = false;
      let success = false;
      let action: ((response?: T) => void) | undefined;
      const $firstReqPromise = this.runNextRequest(parentKey)
        .then(([successAction, value]) => {
          action = successAction;
          success = true;
          resolve({
            enqueued: false,
            value
          });
          return value;
        })
        .catch((error) => {
          reject(error)
          throw error
        })
        .finally(() => {
          done = true;
        });

      setTimeout(() => {
        if (!done) {
          notifications.timeout(maxSecondsBeforeQueue);
          
          this.queues[parentKey].firstRequestTimedOut = true;
          this.emitChange();
          
          resolve({
            enqueued: true,
            $listener: $firstReqPromise
          });

          $firstReqPromise.then((response) => {
            if (success) {
              notifications.success<T>(action, response as T);
            }
          });
        }
      }, maxSecondsBeforeQueue * 1000);
    });
  }

  private finishAndRunNextRequest(parentKey: string) {
    this.queues[parentKey].queue.shift();

    this.emitChange();

    if (this.queues[parentKey].queue.length > 0) {
      const { resolve, reject } = this.queues[parentKey].queue[0];
      this.runNextRequest(parentKey)
        .then(([action, value]) => {
          resolve(value);
          notifications.success(action, value);
        })
        .catch((value) => {
          reject(value);
        });
    }
  }

  private clearQueue(parentKey: string) {
    notifications.error();

    this.queues[parentKey].queue = [];
    this.emitChange();
  }

  private async runNextRequest(parentKey: string) {
    try {
      const { successAction, dispatch } = this.queues[parentKey].queue[0];
      const response = await dispatch();
      this.finishAndRunNextRequest(parentKey);
      return [successAction, response] as const;
    } catch (error) {
      this.clearQueue(parentKey);
      throw error;
    }
  }

  // Listener
  public subscribe(listener: () => void) {
    this.listeners = [...this.listeners, listener];

    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  private queuesSnapshot: Queues | undefined;
  public getSnapshot() {
    if(!this.queuesSnapshot) {
      this.queuesSnapshot = { ...this.queues }
    }
    return this.queuesSnapshot
  }

  private emitChange() {
    this.queuesSnapshot = undefined
    for (let listener of this.listeners) {
      listener();
    }
  }
}

export const AsyncRequestsService = new AsyncRequestsServiceClass();

export const useAsyncQueues = () => {
  const shouldUseAsync = useSelector(isAccountUsingAsyncService)
  const queues: Queues = (React as any).useSyncExternalStore(
    AsyncRequestsService.subscribe.bind(AsyncRequestsService),
    AsyncRequestsService.getSnapshot.bind(AsyncRequestsService)
  );

  const nonEmptyQueues = Object.entries(queues)
    .filter(([, parent]) => {
      let length = parent.queue.length

      // If first request did not timed out yet, we should not count it
      if(!parent.firstRequestTimedOut) {
        length -=1
      }
      return length > 0
    })
    .map(([key, { label, queue }]) => {
      return {
        key,
        label,
        queue: queue.map(({ label }) => ({ label }))
      };
    });

  const amountInQueue = nonEmptyQueues.reduce((acc, { queue: { length } }) => acc + length, 0)
  const isSomethingInTheQueue = shouldUseAsync && amountInQueue > 0 

  return { shouldUseAsync, nonEmptyQueues, amountInQueue, isSomethingInTheQueue };
};

(window as any).AsyncRequestsService = AsyncRequestsService;
