import cropCycleGif from '../public/crop-cycles.gif';
import allFieldGif from '../public/all-fields.gif';
import timelineGif from '../public/timeline.gif';

let UNPUBLISHED_LANG = [];

if (['local', 'qa', 'staging'].includes(process.env.REACT_APP_envName)) {
  UNPUBLISHED_LANG = [{ value: 'ja', name: 'Japanese', shortLabel: 'JA' }];
}

export const DETECT_FIELD_API_KEY = 'authkey=4605ff51-a417-426d-b119-a22e0365c032';
export const fieldDetectionBaseUrl = 'https://gris.syngentadigitalapps.com/fbm/v1/';
export const canadaTownshipGridFieldDetectionUrl = `https://gris.syngentadigitalapps.com/geoserver/ows?${DETECT_FIELD_API_KEY}&service=wfs&version=1.1.0&request=GetFeature&outputFormat=json&typeName=gris:Administrative-Boundaries-Grid-STRM-Varies-Canada-v1&`;
export const VERIFY_ACCESS = '/v2/auth/verify';
export const SYSTEM_RESOURCE_RBAC = 'crn:system';

export const AppConstants = {
  PHOSPHORUS_MULTIPLY_VALUE: 0.4365, //P
  PHOSPHORUS_PENTOXIDE_MULTIPLY_VALUE: 2.2910, //P2O5
  POTASSIUM_MULTIPLY_VALUE: 0.8301, //K
  POTASSIUM_OXIDE_MULTIPLY_VALUE: 1.2051, //K2O
  TERM_AND_CONDITIONS: '',
  ENVIRONMENT: process.env.REACT_APP_envName,
  BASE_REF_ID: process.env.REACT_APP_BASE_REF_ID,
  WHATS_NEW_OFF_FOR_NEW_USER: 24, // in hours
  SIGNUP_TIMER: 30, // in minutes
  DATE_FORMATS: {
    YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
    MMM_SPACE_YEAR: `MMM yyyy`,
    MMM_DD_YYYY: 'MMM DD, YYYY',
    YYYY_DASH_MM_DASH_DD: 'YYYY-MM-DD',
    YYYY_DASH_MM_DASH_DDTHH_COLON_mm_COLON_ss: 'YYYY-MM-DDTHH:mm:ss',
    dddd_SPACE_DD_SPACE_MMM: 'dddd DD MMM',
    DD_SPACE_MMM_COMMA_YYYY: 'DD MMM, YYYY'
  },
  SUPPORTED_LANGS: [
    { value: 'en', name: 'English (USA)', shortLabel: 'EN' },
    { value: 'pt-BR', name: 'Portuguese', shortLabel: 'PT' },
    { value: 'fr', name: 'French', shortLabel: 'FR' },
    { value: 'de', name: 'German', shortLabel: 'DE' },
    { value: 'pl', name: 'Polski', shortLabel: 'PL' },
    { value: 'es', name: 'Spanish', shortLabel: 'ES' },
    { value: 'es-AR', name: 'Spanish (Latin America)', shortLabel: 'ES-AR' },
    { value: 'bg', name: 'Bulgarian', shortLabel: 'BG' },
    { value: 'hu', name: 'Hungarian', shortLabel: 'HU' },
    { value: 'ro', name: 'Romanian', shortLabel: 'RO' },
    { value: 'el', name: 'Greek', shortLabel: 'EL' },
    { value: 'nl', name: 'Dutch', shortLabel: 'NL' },
    { value: 'da', name: 'Danish', shortLabel: 'DA' },
    { value: 'sv', name: 'Swedish', shortLabel: 'SV' },
    { value: 'uk', name: 'Ukrainian', shortLabel: 'UK' },
    { value: 'it', name: 'Italian', shortLabel: 'IT' },
    { value: 'sk', name: 'Slovakian', shortLabel: 'SK' },
    { value: 'ru', name: 'Russian', shortLabel: 'RU' },
    { value: 'cs', name: 'Czech', shortLabel: 'CS' },
    ...UNPUBLISHED_LANG
  ],
  VALIDATIONS: {
    MOBILE_DIGITS_LENGTH: 20,
    MIN_MOBILE_DIGITS_LENGTH: 8,
    NAME_MAX_LENGTH: 50,
    COMMENTS_MAX_LENGTH: 100,
    ZIP_CODE_MAX_LENGTH: 15,
    CITY_MAX_LENGTH: 50,
    COUNTRY_MAX_LENGTH: 25,
    STATE_MAX_LENGTH: 50,
    FIELD_NAME_MAX_LENGTH: 100,
    EIN_MAX_LENGTH: 20,
    ADDRESS_MAX_LENGTH: 100,
    SEASONS_NAME_LENGTH: 50,
    MAX_LENGTH: 255
  },
  EMAIL_MAX_LENGTH: 352,
  VOUCHER_CODE_LENGTH: 19,
  ADD_FIELDS: {
    SEARCH_NAME_MAX_LENGTH: 25
  },
  DATE_PERIOD: {
    DATE_FORMAT: 'MMM DD, YYYY',
    NINE: 9,
    ONE: 1,
    SIX: 6,
    THREE_SIXTY_FIVE: 365,
    THREE_ZERO: 30,
    YEARS: 'years',
    MONTHS: 'months',
    DAYS: 'days'
  },
  CLOCK_FORMAT: {
    FORMAT_12: 'FORMAT_12',
    FORMAT_24: 'FORMAT_24'
  },
  DATE_FORMATS_SETTINGS: ['DD-MM-YYYY', 'DD-MM-YY', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MMM-YYYY'],
  getTimeConstants: (t) => ({
    [AppConstants.CLOCK_FORMAT.FORMAT_12]: [
      '12:00 PM',
      '03:00 PM',
      '06:00 PM',
      '09:00 PM',
      '12:00 AM',
      '03:00 AM',
      '06:00 AM',
      '09:00 AM'
    ],
    [AppConstants.CLOCK_FORMAT.FORMAT_24]: [
      t('common.text.midday'),
      '15:00',
      '18:00',
      '21:00',
      t('common.text.midnight'),
      '03:00',
      '06:00',
      '09:00'
    ]
  }),
  GRAPH_DEFAULT_COLORS: ['#EB8205', '#F9C43D', '#00A0BE'],
  FIELD_BORDER_COLOR: '#31B4F2',
  FIELD_BORDER_DEFAULT_COLOR: '#FFFFFF',
  O_AUTH: {
    responseCode: 'code',
    clientId: '6c08eb4f11184ef4ade36a1bb8220041'
  },
  PROPERTY_WRITE_PERMISSIONS: [
    {
      scope: 'WRITE',
      name: 'TASKS'
    },
    {
      scope: 'WRITE',
      name: 'WAREHOUSES'
    },
    {
      scope: 'WRITE',
      name: 'FIELDS'
    },
    {
      scope: 'WRITE',
      name: 'INFORMATION'
    }
  ],
  PROPERTY_READ_PERMISSIONS: [
    {
      scope: 'READ',
      name: 'TASKS'
    },
    {
      scope: 'READ',
      name: 'WAREHOUSES'
    },
    {
      scope: 'READ',
      name: 'FIELDS'
    }
  ],
  DEFAULT_PERMISSIONS: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      /* {
        scope: 'WRITE',
        name: 'PROPERTIES'
      }, */
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      },
      {
        scope: 'READ',
        name: 'SEASON_AREA'
      },
      {
        scope: 'WRITE',
        name: 'SEASON_AREA'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      }
    ]
  },
  OWNER_WRITE_PERMISSION: {
    scope: 'WRITE',
    name: 'OWNER'
  },
  OWNER_READ_PERMISSION: {
    scope: 'READ',
    name: 'OWNER'
  },
  WRITE_PERMISSION: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'WRITE',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      /* {
        scope: 'WRITE',
        name: 'PROPERTIES'
      }, */
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'READ',
        name: 'OWNER'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      }
    ]
  },
  WRITE_REMOVE_FOR_READ_PERMISSION: {
    permissions: [
      {
        scope: 'WRITE',
        name: 'ORG'
      },
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      }
    ]
  },
  WRITE_REMOVE_FOR_VIEW_PERMISSION: {
    permissions: [
      {
        scope: 'READ',
        name: 'USERS'
      },
      {
        scope: 'READ',
        name: 'OWNER'
      },
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      }
    ]
  },
  WRITE_REMOVE_FOR_DEFAULT_PERMISSION: {
    permissions: [
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      }
    ]
  },
  READ_PERMISSION: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      /* {
        scope: 'WRITE',
        name: 'PROPERTIES'
      }, */
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'USERS'
      },
      {
        scope: 'READ',
        name: 'OWNER'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      }
    ]
  },
  MAX_PAGE_SIZE_FETCH_ORGS: 20,
  FIELD_VERSIONING_TYPE: {
    PREVIOUS_VERSION: 'PREVIOUS_VERSION',
    NEW_VERSION: 'NEW_VERSION'
  },
  POPUP_DETAILS_COMPONENT_MODEL_STRUCTURE: {
    ALL_FIELDS: {
      title: 'Fields',
      gifFile: allFieldGif,
      description:
        'Fields are the smallest parts of your digital farm. You can add new fields and edit the borders of created fields.'
    },
    TIMELINE: {
      title: 'Timeline',
      gifFile: timelineGif,
      description:
        'Timeline shows main information about your fields. Here you find recent changes and associated crops.'
    },
    CROP_CYCLES: {
      title: 'Crop cycles',
      gifFile: cropCycleGif,
      description:
        // eslint-disable-next-line no-irregular-whitespace
        'Crop cycle is a group of fields that share the same crop for a period. Here you can set your crop cycles’ agronomic data.'
    }
  },
  FIX_TOOL_SETTINGS: {
    buffer_zone: 3,
    max_cut_out: 0.01
  },
  PAGE_PATHS: {
    LANDING_ORG: 'landing-organization',
    NO_SIDEBAR_FOR: ['/app/no-access'],
    PROPERTY_FLOW_PAGES: ['property', 'edit-farm'],
    FIELDS_PAGES: [
      'create-fields',
      'all-fields',
      'edit-field',
      'create-region',
      'edit-region',
      'all-regions',
      'draw-field',
      'create-field',
      'manage-regions'
    ],
    CROP_CYCLE_PAGES: ['table-view', 'map-view'],
    INDEPENDANT_PAGES: ['settings', 'my-organizations'],
    TIMELINE_PAGES: ['timeline'],
    CROP_ROTATION_PAGES: ['crop-rotations']
  },
  FIELD_DETECTION_SOURCE_LIST_URL: `${fieldDetectionBaseUrl}providers?${DETECT_FIELD_API_KEY}`,
  FIELD_DETECTION_DEFAULT_PROVIDER: 'Common Land Unit (CLU)',
  FIELD_DETECTION_GET_BOUNDRIES: `${fieldDetectionBaseUrl}boundaries?${DETECT_FIELD_API_KEY}`,
  SUPPORTED_COUNTRIES: [
    // tslint:disable:no-multi-spaces
    { name: 'Afghanistan', value: 'AF' },
    { name: 'Aland Islands', value: 'AX' },
    { name: 'Albania', value: 'AL' },
    { name: 'Algeria', value: 'DZ' },
    { name: 'American Samoa', value: 'AS' },
    { name: 'Andorra', value: 'AD' },
    { name: 'Angola', value: 'AO' },
    { name: 'Anguilla', value: 'AI' },
    { name: 'Antarctica', value: 'AQ' },
    { name: 'Antigua and Barbuda', value: 'AG' },
    { name: 'Argentina', value: 'AR' },
    { name: 'Armenia', value: 'AM' },
    { name: 'Aruba', value: 'AW' },
    { name: 'Australia', value: 'AU' },
    { name: 'Austria', value: 'AT' },
    { name: 'Azerbaijan', value: 'AZ' },
    { name: 'Bahamas', value: 'BS' },
    { name: 'Bahrain', value: 'BH' },
    { name: 'Bangladesh', value: 'BD' },
    { name: 'Barbados', value: 'BB' },
    { name: 'Belarus', value: 'BY' },
    { name: 'Belgium', value: 'BE' },
    { name: 'Belize', value: 'BZ' },
    { name: 'Benin', value: 'BJ' },
    { name: 'Bermuda', value: 'BM' },
    { name: 'Bhutan', value: 'BT' },
    { name: 'Plurinational State of Bolivia', value: 'BO' },
    { name: 'Bonaire Sint Eustatius and Saba', value: 'BQ' },
    { name: 'Bosnia and Herzegovina', value: 'BA' },
    { name: 'Botswana', value: 'BW' },
    { name: 'Bouvet Island', value: 'BV' },
    { name: 'Brazil', value: 'BR' },
    { name: 'British Indian Ocean Territory', value: 'IO' },
    { name: 'Brunei Darussalam', value: 'BN' },
    { name: 'Bulgaria', value: 'BG' },
    { name: 'Burkina Faso', value: 'BF' },
    { name: 'Burundi', value: 'BI' },
    { name: 'Cambodia', value: 'KH' },
    { name: 'Cameroon', value: 'CM' },
    { name: 'Canada', value: 'CA' },
    { name: 'Cape Verde', value: 'CV' },
    { name: 'Cayman Islands', value: 'KY' },
    { name: 'Central African Republic', value: 'CF' },
    { name: 'Chad', value: 'TD' },
    { name: 'Chile', value: 'CL' },
    { name: 'China', value: 'CN' },
    { name: 'Christmas Island', value: 'CX' },
    { name: 'Cocos (Keeling) Islands', value: 'CC' },
    { name: 'Colombia', value: 'CO' },
    { name: 'Comoros', value: 'KM' },
    { name: 'Congo', value: 'CG' },
    { name: 'Democratic Republic of the Congo', value: 'CD' },
    { name: 'Cook Islands', value: 'CK' },
    { name: 'Costa Rica', value: 'CR' },
    { name: `Cote d'Ivoire`, value: 'CI' },
    { name: 'Croatia', value: 'HR' },
    { name: 'Cuba', value: 'CU' },
    { name: 'Curacao', value: 'CW' },
    { name: 'Cyprus', value: 'CY' },
    { name: 'Czech Republic', value: 'CZ' },
    { name: 'Denmark', value: 'DK' },
    { name: 'Djibouti', value: 'DJ' },
    { name: 'Dominica', value: 'DM' },
    { name: 'Dominican Republic', value: 'DO' },
    { name: 'Ecuador', value: 'EC' },
    { name: 'Egypt', value: 'EG' },
    { name: 'El Salvador', value: 'SV' },
    { name: 'Equatorial Guinea', value: 'GQ' },
    { name: 'Eritrea', value: 'ER' },
    { name: 'Estonia', value: 'EE' },
    { name: 'Ethiopia', value: 'ET' },
    { name: 'Falkland Islands (Malvinas)', value: 'FK' },
    { name: 'Faroe Islands', value: 'FO' },
    { name: 'Fiji', value: 'FJ' },
    { name: 'Finland', value: 'FI' },
    { name: 'France', value: 'FR' },
    { name: 'French Guiana', value: 'GF' },
    { name: 'French Polynesia', value: 'PF' },
    { name: 'French Southern Territories', value: 'TF' },
    { name: 'Gabon', value: 'GA' },
    { name: 'Gambia', value: 'GM' },
    { name: 'Georgia', value: 'GE' },
    { name: 'Germany', value: 'DE' },
    { name: 'Ghana', value: 'GH' },
    { name: 'Gibraltar', value: 'GI' },
    { name: 'Greece', value: 'GR' },
    { name: 'Greenland', value: 'GL' },
    { name: 'Grenada', value: 'GD' },
    { name: 'Guadeloupe', value: 'GP' },
    { name: 'Guam', value: 'GU' },
    { name: 'Guatemala', value: 'GT' },
    { name: 'Guernsey', value: 'GG' },
    { name: 'Guinea', value: 'GN' },
    { name: 'Guinea-Bissau', value: 'GW' },
    { name: 'Guyana', value: 'GY' },
    { name: 'Haiti', value: 'HT' },
    { name: 'Heard Island and McDonald Islands', value: 'HM' },
    { name: 'Holy See (Vatican City State)', value: 'VA' },
    { name: 'Honduras', value: 'HN' },
    { name: 'Hong Kong', value: 'HK' },
    { name: 'Hungary', value: 'HU' },
    { name: 'Iceland', value: 'IS' },
    { name: 'India', value: 'IN' },
    { name: 'Indonesia', value: 'ID' },
    { name: 'Islamic Republic of Iran', value: 'IR' },
    { name: 'Iraq', value: 'IQ' },
    { name: 'Ireland', value: 'IE' },
    { name: 'Isle of Man', value: 'IM' },
    { name: 'Israel', value: 'IL' },
    { name: 'Italy', value: 'IT' },
    { name: 'Jamaica', value: 'JM' },
    { name: 'Japan', value: 'JP' },
    { name: 'Jersey', value: 'JE' },
    { name: 'Jordan', value: 'JO' },
    { name: 'Kazakhstan', value: 'KZ' },
    { name: 'Kenya', value: 'KE' },
    { name: 'Kiribati', value: 'KI' },
    { name: `Democratic People's Republic of Korea`, value: 'KP' },
    { name: 'Republic of Korea', value: 'KR' },
    { name: 'Kuwait', value: 'KW' },
    { name: 'Kyrgyzstan', value: 'KG' },
    { name: `Lao People's Democratic Republic`, value: 'LA' },
    { name: 'Latvia', value: 'LV' },
    { name: 'Lebanon', value: 'LB' },
    { name: 'Lesotho', value: 'LS' },
    { name: 'Liberia', value: 'LR' },
    { name: 'Libya', value: 'LY' },
    { name: 'Liechtenstein', value: 'LI' },
    { name: 'Lithuania', value: 'LT' },
    { name: 'Luxembourg', value: 'LU' },
    { name: 'Macao', value: 'MO' },
    { name: 'The Former Yugoslav Republic of Macedonia', value: 'MK' },
    { name: 'Madagascar', value: 'MG' },
    { name: 'Malawi', value: 'MW' },
    { name: 'Malaysia', value: 'MY' },
    { name: 'Maldives', value: 'MV' },
    { name: 'Mali', value: 'ML' },
    { name: 'Malta', value: 'MT' },
    { name: 'Marshall Islands', value: 'MH' },
    { name: 'Martinique', value: 'MQ' },
    { name: 'Mauritania', value: 'MR' },
    { name: 'Mauritius', value: 'MU' },
    { name: 'Mayotte', value: 'YT' },
    { name: 'Mexico', value: 'MX' },
    { name: 'Federated States of Micronesia', value: 'FM' },
    { name: 'Republic of Moldova', value: 'MD' },
    { name: 'Monaco', value: 'MC' },
    { name: 'Mongolia', value: 'MN' },
    { name: 'Montenegro', value: 'ME' },
    { name: 'Montserrat', value: 'MS' },
    { name: 'Morocco', value: 'MA' },
    { name: 'Mozambique', value: 'MZ' },
    { name: 'Myanmar', value: 'MM' },
    { name: 'Namibia', value: 'NA' },
    { name: 'Nauru', value: 'NR' },
    { name: 'Nepal', value: 'NP' },
    { name: 'Netherlands', value: 'NL' },
    { name: 'New Caledonia', value: 'NC' },
    { name: 'New Zealand', value: 'NZ' },
    { name: 'Nicaragua', value: 'NI' },
    { name: 'Niger', value: 'NE' },
    { name: 'Nigeria', value: 'NG' },
    { name: 'Niue', value: 'NU' },
    { name: 'Norfolk Island', value: 'NF' },
    { name: 'Northern Mariana Islands', value: 'MP' },
    { name: 'Norway', value: 'NO' },
    { name: 'Oman', value: 'OM' },
    { name: 'Pakistan', value: 'PK' },
    { name: 'Palau', value: 'PW' },
    { name: 'State of Palestine', value: 'PS' },
    { name: 'Panama', value: 'PA' },
    { name: 'Papua New Guinea', value: 'PG' },
    { name: 'Paraguay', value: 'PY' },
    { name: 'Peru', value: 'PE' },
    { name: 'Philippines', value: 'PH' },
    { name: 'Pitcairn', value: 'PN' },
    { name: 'Poland', value: 'PL' },
    { name: 'Portugal', value: 'PT' },
    { name: 'Puerto Rico', value: 'PR' },
    { name: 'Qatar', value: 'QA' },
    { name: 'Reunion', value: 'RE' },
    { name: 'Romania', value: 'RO' },
    { name: 'Russian Federation', value: 'RU' },
    { name: 'Rwanda', value: 'RW' },
    { name: 'Saint Barthelemy', value: 'BL' },
    { name: 'Saint Helena Ascension and Tristan da Cunha', value: 'SH' },
    { name: 'Saint Kitts and Nevis', value: 'KN' },
    { name: 'Saint Lucia', value: 'LC' },
    { name: 'Saint Martin (French part)', value: 'MF' },
    { name: 'Saint Pierre and Miquelon', value: 'PM' },
    { name: 'Saint Vincent and the Grenadines', value: 'VC' },
    { name: 'Samoa', value: 'WS' },
    { name: 'San Marino', value: 'SM' },
    { name: 'Sao Tome and Principe', value: 'ST' },
    { name: 'Saudi Arabia', value: 'SA' },
    { name: 'Senegal', value: 'SN' },
    { name: 'Serbia', value: 'RS' },
    { name: 'Seychelles', value: 'SC' },
    { name: 'Sierra Leone', value: 'SL' },
    { name: 'Singapore', value: 'SG' },
    { name: 'Sint Maarten (Dutch part)', value: 'SX' },
    { name: 'Slovakia', value: 'SK' },
    { name: 'Slovenia', value: 'SI' },
    { name: 'Solomon Islands', value: 'SB' },
    { name: 'Somalia', value: 'SO' },
    { name: 'South Africa', value: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { name: 'South Sudan', value: 'SS' },
    { name: 'Spain', value: 'ES' },
    { name: 'Sri Lanka', value: 'LK' },
    { name: 'Sudan', value: 'SD' },
    { name: 'Suriname', value: 'SR' },
    { name: 'Svalbard and Jan Mayen', value: 'SJ' },
    { name: 'Swaziland', value: 'SZ' },
    { name: 'Sweden', value: 'SE' },
    { name: 'Switzerland', value: 'CH' },
    { name: 'Syrian Arab Republic', value: 'SY' },
    { name: 'Taiwan, Province of China', value: 'TW' },
    { name: 'Tajikistan', value: 'TJ' },
    { name: 'United Republic of Tanzania', value: 'TZ' },
    { name: 'Thailand', value: 'TH' },
    { name: 'Timor-Leste', value: 'TL' },
    { name: 'Togo', value: 'TG' },
    { name: 'Tokelau', value: 'TK' },
    { name: 'Tonga', value: 'TO' },
    { name: 'Trinidad and Tobago', value: 'TT' },
    { name: 'Tunisia', value: 'TN' },
    { name: 'Turkey', value: 'TR' },
    { name: 'Turkmenistan', value: 'TM' },
    { name: 'Turks and Caicos Islands', value: 'TC' },
    { name: 'Tuvalu', value: 'TV' },
    { name: 'Uganda', value: 'UG' },
    { name: 'Ukraine', value: 'UA' },
    { name: 'United Arab Emirates', value: 'AE' },
    { name: 'United Kingdom', value: 'GB' },
    { name: 'United States', value: 'US' },
    { name: 'United States Minor Outlying Islands', value: 'UM' },
    { name: 'Uruguay', value: 'UY' },
    { name: 'Uzbekistan', value: 'UZ' },
    { name: 'Vanuatu', value: 'VU' },
    { name: 'Bolivarian Republic of Venezuela', value: 'VE' },
    { name: 'Viet Nam', value: 'VN' },
    { name: 'Virgin Islands - British', value: 'VG' },
    { name: 'Virgin Islands - US', value: 'VI' },
    { name: 'Wallis and Futuna', value: 'WF' },
    { name: 'Western Sahara', value: 'EH' },
    { name: 'Yemen', value: 'YE' },
    { name: 'Zambia', value: 'ZM' },
    { name: 'Zimbabwe', value: 'ZW' }
  ],
  GET_RBAC_PERMISSION_EXPIRY_DURATION: () => {
    if (['qa'].includes(process.env.REACT_APP_envName)) {
      return 15 * 60 * 1000; // in milliseconds
    }
    return 1 * 60 * 60 * 1000; // in milliseconds
  },
  TEST_ENV: ['local', 'qa', 'staging'],
  VISIBILITY_FILTER: {
    ANY: 'ANY',
    ONLY_HIDDEN: 'ONLY_HIDDEN',
    NOT_HIDDEN: 'NOT_HIDDEN'
  },
  USO_SIGPAC: 'USO_SIGPAC',
  PENDIENTE_MEDIA: 'PENDIENTE_MEDIA',
  SIGPAC_FIELD_CLIENT_ID: process.env.REACT_APP_SIGPAC_CLIENT_ID || '6c08eb4f11184ef4ade36a1bb8220041',
  SUPPORTED_CATALOG_COUNTRIES: [
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BL',
    'BM',
    'BN',
    'BO',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BV',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GS',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HM',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MF',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'UM',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',
  ],
  LEGACY_PRODUCT_TYPES: [
    'VARIETY',
    'FERTILIZER',
    'PART',
    'FUEL',
    'PLANT_ACTIVATOR'
  ]
};
