/* eslint-disable no-param-reassign */
/**
 * i18n : https://react.i18next.com/latest/using-with-hooks
 */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import {
  getLanguageCode,
  getLocaleTempUsingCookies,
  getLocaleUsingCookies
} from './utils/commonMethods';
import { defaultLanguage, i18nToMoment } from 'app.constant';

const lngByCookie = getLanguageCode(getLocaleTempUsingCookies()) || getLocaleUsingCookies() || defaultLanguage;
const lng = i18nToMoment[lngByCookie] ?? lngByCookie;

if (process.env.NODE_ENV !== 'test' && lng !== defaultLanguage) {
  import(`moment/locale/${lng}`).then().catch();
}

const WebpackHash = process.env.REACT_APP_BUILD_TIME || Date.now();
moment.locale(lng);

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: defaultLanguage,
    interpolation: { escapeValue: false },
    backend: { loadPath: `/i18n/{{lng}}.json?hash=${WebpackHash}` }
  });

export default i18n;
