//tslint:disable
import {
  format as formatDFNS,
  parse as parseDFNS,
  isBefore as isBeforeDFNS,
  isAfter as isAfterDFNS,
  isLeapYear as isLeapYearDFNS,
  getDate as getDateDFNS,
  getDay as getDayDFNS,
  getWeek as getWeekDFNS,
  getYear as getYearDFNS,
  getWeeksInMonth as getWeeksInMonthDFNS,
  isDate as isDateDFNS,
  isMatch as isMatchDFNS,
  isValid as isValidDFNS,
  addDays as addDaysDFNS,
  addMonths as addMonthsDFNS,
  addYears as addYearsDFNS,
  subDays as subDaysDFNS,
  subMonths as subMonthsDFNS,
  subYears as subYearsDFNS,
  differenceInDays as differenceInDaysDFNS,
  differenceInHours as differenceInHoursDFNS
} from 'date-fns';

export const DATE_FORMATS = {
  YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
  MMM_SPACE_YEAR: `MMM yyyy`,
  YYYY_DASH_MM_DASH_DD: 'yyyy-MM-dd',
  YYYY_DASH_MM_DASH_DD_NUMBER: 'yyyy-MM-DD',
  YYYY_SHLASH_MM_SHLASH_DD: 'yyyy/MM/dd',
  YYYY_DASH_MM_DASH_DDTHH_COLON_mm_COLON_ss: 'YYYY-MM-DDTHH:mm:ss',
  dddd_SPACE_DD_SPACE_MMM: 'dddd DD MMM',
  DD_DASH_MM_DASH_YYYY: 'dd-MM-yyyy',
  DD_DASH_MM_DASH_YY: 'dd-MM-yy',
  MM_DASH_DD_DASH_YYYY: 'MM-dd-yyyy',
  DD_DASH_MMM_DASH_YYYY: 'dd-MMM-yyyy'
};

export const DATE_FORMAT_MOMENT = {
  'DD-MM-YYYY': DATE_FORMATS.DD_DASH_MM_DASH_YYYY,
  'DD-MM-YY': DATE_FORMATS.DD_DASH_MM_DASH_YY,
  'YYYY-MM-DD': DATE_FORMATS.YYYY_DASH_MM_DASH_DD,
  'MM-DD-YYYY': DATE_FORMATS.MM_DASH_DD_DASH_YYYY,
  'DD-MMM-YYYY': DATE_FORMATS.DD_DASH_MMM_DASH_YYYY
};

export const toDateFnsFormat = (Dateformat = '') => {
  return Dateformat.replace(/YYYY/g, 'yyyy').replace(/DD/g, 'dd');
};

export const newDate = (date) => {
  return date ? new Date(date) : new Date();
};

export const formatDateFNS = (date, formatString = DATE_FORMATS.DD_DASH_MMM_DASH_YYYY) => {
  const date1 = date ? newDate(date) : new Date();
  return formatDFNS(date1, formatString);
};

export const parseDate = (dateString, formatString = DATE_FORMATS.DD_DASH_MMM_DASH_YYYY) => {
  return parseDFNS(dateString, formatString, new Date());
};

export const isBefore = (date1, date2) => {
  return isBeforeDFNS(new Date(date1), new Date(date2));
};

export const isAfter = (date1, date2) => {
  return isAfterDFNS(newDate(date1), newDate(date2));
};

export const isLeapYear = (date) => {
  return isLeapYearDFNS(date);
};

export const getDate = (date) => {
  return getDateDFNS(date);
};

export const getDay = (date) => {
  return getDayDFNS(date);
};

export const getWeek = (date) => {
  return getWeekDFNS(date);
};

export const getYear = (date) => {
  return getYearDFNS(date);
};

export const getWeeksInMonth = (date) => {
  return getWeeksInMonthDFNS(date);
};

export const isDate = (date) => {
  return isDateDFNS(date);
};

export const isMatch = (date, pattern) => {
  return isMatchDFNS(date, pattern);
};

export const isValid = (date) => {
  return isValidDFNS(date);
};

export const addDays = (date, days) => {
  return addDaysDFNS(newDate(date), days);
};

export const addMonths = (date, months) => {
  return addMonthsDFNS(newDate(date), months);
};

export const addYears = (date, years) => {
  return addYearsDFNS(newDate(date), years);
};

export const subDays = (date, days) => {
  return subDaysDFNS(newDate(date), days);
};

export const subMonths = (date, months) => {
  return subMonthsDFNS(newDate(date), months);
};

export const subYears = (date, years) => {
  return subYearsDFNS(newDate(date), years);
};

export const differenceInDays = (date1, date2) => {
  return differenceInDaysDFNS(new Date(date1), new Date(date2));
};

export const differenceInHours = (date1, date2) => {
  return differenceInHoursDFNS(new Date(date1), new Date(date2));
};
