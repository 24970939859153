import find from 'lodash/find';

import { store } from 'redux/reducer/store';
import { getCurrentWorkspace } from 'redux/selectors/userSelector';
import { VERIFY_ACCESS } from 'utils/app.constants';
import { FARM_ROUTE, LANDING_ORG_ROUTE } from 'utils/history';
import { request } from '../../utils/axios';
import {
  getHeaders,
  clearAccessToken,
  getAppHostsInfo,
  isSuperUser,
  isSupportUser,
} from '../../utils/commonMethods';
import {
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_LOADING,
  FETCH_USER_WORKSPACE_SUCCESS,
  FETCH_USER_WORKSPACE_ERROR,
  FETCH_USER_WORKSPACE_LOADING,
  FETCH_CURRENT_WORKSPACE_SUCCESS,
  OPEN_USER_LOCATION_MODAL,
  CLOSE_USER_LOCATION_MODAL
} from './types';

export function fetchUserProfileLoading() {
  return {
    type: FETCH_USER_PROFILE_LOADING
  };
}

export function fetchUserProfileError(error) {
  return {
    type: FETCH_USER_PROFILE_ERROR,
    payload: error
  };
}

export function fetchUserProfileSuccess(user) {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: user
  };
}

let userProfilePromise = undefined;

export const fetchUserProfile = () => {
  return async (dispatch) => {
    if (userProfilePromise) {
      dispatch(fetchUserProfileSuccess(userProfilePromise.data))
      return userProfilePromise;
    }
    dispatch(fetchUserProfileLoading());
    try {
      userProfilePromise = await request.get(
        `/v2/accounts/me?include_extended=true&attributes=licensing_accounts,is_using_rbac`,
        {
          headers: getHeaders(),
          useCache: false
        }
      );
      dispatch(fetchUserProfileSuccess(userProfilePromise.data));
      return userProfilePromise.data;
    } catch (error) {
      userProfilePromise = undefined;
      dispatch(fetchUserProfileError(error));
      Promise.reject(error);
    }
    return null;
  };
};

export const logoutUser = async () => {
  try {
    clearAccessToken();
    sessionStorage.removeItem('tempTokens');
    sessionStorage.removeItem('SESSION_USER_DETAILS');
    const { account } = getAppHostsInfo();
    window.location.href = `https://${account}/exit`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error on logout', error);
  }
};

export function fetchUserWorkspaceLoading() {
  return {
    type: FETCH_USER_WORKSPACE_LOADING
  };
}

export function fetchUserWorkspaceError(error) {
  return {
    type: FETCH_USER_WORKSPACE_ERROR,
    payload: error
  };
}

export function fetchUserWorkspaceSuccess(user) {
  return {
    type: FETCH_USER_WORKSPACE_SUCCESS,
    payload: user
  };
}

export function fetchCurrentWorkspaceSuccess(user) {
  return {
    type: FETCH_CURRENT_WORKSPACE_SUCCESS,
    payload: user
  };
}

let userWorkspacePromise = undefined;
export const fetchUserWorkspace = () => async (dispatch) => {
  if (userWorkspacePromise) {
    dispatch(fetchUserWorkspaceSuccess(userWorkspacePromise.data))
    return userWorkspacePromise.data;
  }
  dispatch(fetchUserWorkspaceLoading());
  try {
    if (userProfilePromise) {
      const defaultWorkspaceId = userProfilePromise.default_workspace_id;
      if (defaultWorkspaceId) {
        const res = await request.get(`/v2/licensing/accounts/${defaultWorkspaceId}`, {
          headers: getHeaders(),
          useCache: false
        });
        userWorkspacePromise = res
        dispatch(fetchUserWorkspaceSuccess(res.data)); 
        return res.data;
      }
      return undefined;
    }
    const res = await request.get(
      `/v2/accounts/me?include_extended=true&attributes=licensing_accounts,is_using_rbac`,
      {
        headers: getHeaders(),
        useCache: false
      }
    );
    if (res && res.data) {
      const defaultWorkspaceId =
        res.data.default_licensing_account_id || res.data.default_workspace_id;
      if (defaultWorkspaceId) {
        const resWithWorkspace = await request.get(`/v2/workspaces/${defaultWorkspaceId}`, 
          {
            headers: getHeaders(),
            useCache: false
          });
        userWorkspacePromise = resWithWorkspace
        dispatch(fetchUserWorkspaceSuccess(resWithWorkspace.data));
        return resWithWorkspace.data;
      }
      return undefined;
    }
  } catch (error) {
    dispatch(fetchUserWorkspaceError(error));
  }
  return null;
};

let workspaceCache = {};
export const fetchWorkspaceById = (workspaceId) => async (dispatch) => {
  if(workspaceCache[workspaceId]) {
    dispatch(fetchUserWorkspaceSuccess(workspaceCache[workspaceId].data));
    dispatch(fetchCurrentWorkspaceSuccess(workspaceCache[workspaceId].data));
    return workspaceCache[workspaceId].data;
  }
  dispatch(fetchUserWorkspaceLoading());
  try {
    workspaceCache[workspaceId] = await request.get(`/v2/workspaces/${workspaceId}`, 
      {
        headers: getHeaders(),
        useCache: false
      });
    if (workspaceCache[workspaceId] && workspaceCache[workspaceId].data) {
      dispatch(fetchUserWorkspaceSuccess(workspaceCache[workspaceId].data));
      dispatch(fetchCurrentWorkspaceSuccess(workspaceCache[workspaceId].data));
      return workspaceCache[workspaceId].data;
    }
  } catch (error) {
    workspaceCache[workspaceId] = undefined
    dispatch(fetchUserWorkspaceError(error));
    return error;
  }
  return null;
};

export const isSupportUserWithoutDeleteOperation = (actionsArray) => {
  let isPermission = false;
  if (isSupportUser()) {
    isPermission = true;
    find(actionsArray, (action) => {
      if (action?.action.includes('delete')) {
        isPermission = false;
        return true;
      }
    });
  }
  return isPermission;
};

export const isUsingRBAC = (stateValue) => {
  const state = stateValue ? stateValue : store.getState();
  return (
    state.user.userProfile.is_using_rbac && state.rollout.flags.isRBACVerificationEnabledOnBaseFront
  );
};

export const verifyPermision = async (
  actionsArray
) => {
  const reduxState = store.getState();

  if (isUsingRBAC(reduxState)) {
    if (isSuperUser() || isSupportUserWithoutDeleteOperation(actionsArray)) {
      return { allowed_operations: actionsArray };
    }
    const payloadAccessCheck = {
      operations: actionsArray
    };
    try {
      const { data } = await request.post(VERIFY_ACCESS, payloadAccessCheck, {
        headers: getHeaders(),
        useCache: false
      });
      return data;
    } catch (error) {
      return error;
    }
  } else {
    return { allowed_operations: actionsArray };
  }
};

export const isPermissionAccess = (permissions, action, isAccessOfOldPermission = true) => {
  const reduxState = store.getState();
  if (isUsingRBAC(reduxState) === false) {
    return isAccessOfOldPermission;
  }
  if (typeof action === 'string') {
    return find(permissions, { action });
  }
  if (Array.isArray(action)) {
    return find(action, (actionItem) => {
      return find(permissions, { action: actionItem });
    });
  }
};

export const openLocationModal = (isOpen) => {
  if (isOpen) {
    return {
      type: OPEN_USER_LOCATION_MODAL
    };
  }
  return {
    type: CLOSE_USER_LOCATION_MODAL
  };
};

const getWSORGResourceString = (workspace_id, org_id) =>
  `crn:workspace:${workspace_id}/org/${org_id}`;
const getWSORGPropertyResourceString = (workspace_id, org_id, property_id) =>
  `crn:workspace:${workspace_id}/org/${org_id}/property/${property_id}`;
const getIdString = (key) => window.location.pathname.split(key) || [];

const isDeepAction = window.location.pathname.includes('deep-actions')
export const getOrgIdFromUrl = () => {
  if(isDeepAction) {
    return (new URLSearchParams(window.location.search)).get('org_id')
  }
  try {
    return getIdString(LANDING_ORG_ROUTE)[1]?.split('/')[1];
  } catch (e) {
    return '';
  }
};

export const getPropertyIdFromUrl = (config = {}) => {
  if(isDeepAction) {
    return (new URLSearchParams(window.location.search)).get('property_id')
  }
  try {
    return getIdString(config.propertyKey || FARM_ROUTE)[1]?.split('/')[1];
  } catch (e) {
    return '';
  }
};

export const getWSResource = () => {
  const reduxState = store.getState();
  const workspace_id = getCurrentWorkspace(reduxState);
  return `crn:workspace:${workspace_id}`;
};

export const getWSOrgResource = (params = {}) => {
  const reduxState = store.getState();
  let workspace_id = getCurrentWorkspace(reduxState);
  let organization_id = getOrgIdFromUrl();
  // when no orgid in url, get to through params
  if ('orgId' in params) {
    organization_id = params.orgId;
  }
  if ('wsId' in params) {
    workspace_id = params.wsId;
  }
  return getWSORGResourceString(workspace_id, organization_id);
};

export const getWSOrgPropertyResource = (config) => {
  const reduxState = store.getState();
  const workspace_id = getCurrentWorkspace(reduxState);
  return getWSORGPropertyResourceString(
    workspace_id,
    getOrgIdFromUrl(),
    getPropertyIdFromUrl(config)
  );
};
